import React from 'react';
import NotFoundCard from '../NotFoundCard';
import PropTypes from 'prop-types';
import { isOnlySummaryScreenDomains } from './defaultValues';
import { connect } from 'react-redux';
import { openUrl } from '../../utils/helper';
import useResize from './utils/useResize';
import { Link, withRouter } from 'react-router-dom';
import { currencyMapper } from '../../utils/entity_mapping';
import { infoIcon } from './utils/svgs';
import { useExchangeContext } from './wrapper';

const NotFound = props => {
  const { conHeight } = useResize();
  const { orderInfo, tracking, exchangeConfig, user, page } = props;
  const { action, addresses } = orderInfo;
  const { config } = tracking.theme;
  const selectedAddress = addresses[action.address.select];
  const selectedProdList = Object.values(orderInfo.request);
  const isExchange = selectedProdList?.[0]?.type === 'exchange';
  const {
    labels: { exchangeTexts }
  } = useExchangeContext();
  if (isOnlySummaryScreenDomains.includes(props.user.subdomain)) {
    return (
      <div
        className="d-flex flex-col items-center"
        style={{ height: conHeight }}
      >
        <div
          className="bg-error mrg-top-30 d-flex items-center justify-center rounded-pill"
          style={{ width: 50, height: 50 }}
        >
          <span className="icon-close white-text fs-32" />
        </div>
        <p className="mrg-top-20 mrg-btm-20 fs-20 text-uppercase fw-700 text-primary-color">
          Exchange Unsuccessful
        </p>
        {selectedProdList &&
          selectedProdList.length > 0 &&
          ['abfrl-test2', 'pantaloons'].includes(user.subdomain) && (
            <>
              <hr className="divider-horizontal mrg-left-20 mrg-right-20" />

              <div className="bd-primary bd-1 xs-bd-0 md-flex xd-flex flex-col overflow-auto overflow-x-hidden justify-between pd-16 exchange-summary-container shadow-0">
                <div className="row">
                  <div className="col-md-24 mrg-btm-20 col-xs-24">
                    {/* <div className="fw-600 mrg-btm-5 text-primary-color">
              {isExchange ? 'Exchange ' : 'Return '} Summary
            </div> */}
                    <div className="md-flex xs-flex justify-between">
                      <div>
                        {selectedProdList.map(product => (
                          <div
                            key={product.id}
                            className="md-flex space-y-3 xs-flex text-secondary-color"
                          >
                            <img
                              alt="product"
                              className="mrg-right-10 object-fit-cover img-rounded"
                              width={54}
                              height={54}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  '/images/img-placeholder.svg';
                              }}
                              src={
                                product?.images || '/images/img-placeholder.svg'
                              }
                            />
                            <div
                              className={`d-flex ${
                                isOnlySummaryScreenDomains.includes(
                                  user.subdomain
                                )
                                  ? ''
                                  : 'justify-between '
                              }flex-col`}
                            >
                              <div className="fw-600 fs-16 text-primary-color">
                                {product?.description}
                              </div>
                              {isOnlySummaryScreenDomains.includes(
                                user.subdomain
                              ) ? ( // currently doing explicit checks if brand wise customizations required then create a config
                                <>
                                  <div>
                                    {page.newSize && (
                                      <>
                                        <span>
                                          New Size :{' '}
                                          {decodeURIComponent(page.newSize)}{' '}
                                        </span>
                                        <span className="mrg-left-10 mrg-right-10 text-theme">
                                          |
                                        </span>
                                      </>
                                    )}
                                    <span>Qty : 1</span>
                                  </div>
                                  <div className="fw-600">
                                    {orderInfo.currency ??
                                      currencyMapper(config.country_code)}{' '}
                                    {product?.price}
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <span className="fw-600">
                                    {orderInfo.currency ??
                                      currencyMapper(config.country_code)}{' '}
                                    {product?.price}
                                  </span>
                                  <span className="mrg-left-10 mrg-right-10 text-theme">
                                    |
                                  </span>
                                  <span>
                                    {isExchange ? exchangeTexts?.[1] : 'Return'}{' '}
                                    Qty : {product?.quantity}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-24 mrg-btm-20 col-xs-24">
                    <div // used in css
                    >
                      <div className="fw-600 mrg-btm-5 text-primary-color">
                        Address
                      </div>
                      <div className="md-flex xs-flex justify-between">
                        <div className="text-secondary-color">
                          <div className="text-primary-color fw-600">
                            {selectedAddress?.name}
                          </div>
                          <div>{selectedAddress?.address}</div>
                          <div>
                            {selectedAddress?.pincode} {selectedAddress?.city}{' '}
                            {selectedAddress?.phone}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {exchangeConfig.instruction && isExchange && (
                  <div className="row">
                    <div className="col-md-24 pd-15  col-xs-24">
                      <div className="bg-brand-primary bd-primary bg-light pd-15 md-flex xs-flex rounded">
                        <div>
                          <div className="mrg-right-10">
                            {infoIcon(user.subdomain)}
                          </div>
                        </div>
                        {/* <div> */}
                        {/* <div className="fw-600 mrg-btm-5 text-primary-color">
                  {isExchange ? 'Exchange' : 'Return'} Instructions :
                </div> */}
                        <div className="text-secondary-color">
                          {exchangeConfig.instruction ||
                            'Please note: The concerned pickup address may only be selected from existing registered delivery addresses. New Pickup addresses may not be entered at this time.'}
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="full-width pd-left-20 w-100 d-flex align-items-center text-left text-theme">
                {infoIcon(user.subdomain)}
                <span className="mrg-left-5 text-theme">
                  Exchange Unsuccessful
                </span>
              </div>
            </>
          )}
        <p className="text-secondary-color mrg-btm-30 pd-left-15 mrg-left-30 mrg-right-20">
          <div>
            Your exchange request could not be processed. Please opt for Return,
            if you wish to send the product back to us. For assistance please
            contact our{' '}
            <a
              className="btn-underline text-primary-color"
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${props.tracking.theme.meta_data.email}`}
            >
              Customer Service
            </a>
          </div>
          <Link to={`/returns${props.location.search}`}>
            <span className="btn-underline">Return</span>
          </Link>
        </p>
        <div className="d-flex mt-auto pd-left-10 pd-right-10 full-width">
          <button
            onClick={() =>
              openUrl(
                props.tracking.theme.meta_data.my_order_url ?? '',
                'blank'
              )
            }
            className="ant-button ghost-black mrg-right-10 rounded-pill compact full-width mrg-btm-10"
          >
            My orders
          </button>
          <Link to={`/returns${props.location.search}`} className="full-width">
            <button
              className={`ant-button full-width rounded-pill compact full-width mrg-btm-10${
                ['pantaloons', 'abfrl-test2'].includes(props.user.subdomain)
                  ? ' ghost-black'
                  : ''
              }`}
            >
              Return
            </button>
          </Link>
        </div>
      </div>
    );
  }
  return <NotFoundCard {...props} />;
};

NotFound.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string,
  noMessage: PropTypes.bool,
  user: PropTypes.any,
  tracking: PropTypes.any,
  location: PropTypes.any,
  orderInfo: PropTypes.any,
  exchangeConfig: PropTypes.any,
  page: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  exchangeConfig: state.exchangeConfig,
  page: state.page
});

export default connect(mapStateToProps)(withRouter(NotFound));
