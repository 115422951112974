/* eslint-disable react/no-string-refs */
/* eslint-disable react/jsx-key */
import * as React from 'react';
import PropTypes from 'prop-types';

export default class Input extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    maxlength: PropTypes.number,
    readOnly: PropTypes.bool,
    pattern: PropTypes.any
  };

  static defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
    onKeyUp: () => {},
    onBlur: () => {}
  };

  _handleKeyUp = e => {
    if (e.which === 13) {
      this.props.onSubmit(e);
      return;
    }

    this.props.onKeyUp(e);
  };

  componentDidMount = () => {
    // if (this.props.autoFocus) {
    //   this.refs.input.focus();
    // }
  };

  render() {
    return (
      <input
        ref="input"
        className={this.props.className}
        onChange={this.props.onChange}
        onKeyUp={this._handleKeyUp}
        onBlur={this.props.onBlur}
        placeholder={this.props.placeholder}
        value={this.props.value}
        name={this.props.name}
        type={this.props.type}
        maxLength={this.props.maxlength}
        required={this.props.required}
        readOnly={this.props.readOnly}
        pattern={this.props.pattern}
      />
    );
  }
}
