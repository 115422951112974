import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { exchangeNudgeHandler } from '../../service';
import { useExchangeContext } from './wrapper';

const Nudge = ({
  tracking,
  type,
  position,
  selectedReason,
  exchangeNudgeHandler,
  className,
  onClick
}) => {
  const { setState, goForward } = useExchangeContext();
  const nudgeConfig =
    tracking.theme?.config?.exchangeNudge?.enterprise_nudge_config ?? {};

  const selectedReasonNudge = tracking.theme?.config?.exchangeNudge?.reason_based?.find(
    item => item.return_reason_name === selectedReason
  );

  const onNudgeClick = () => {
    if (onClick) {
      onClick();
    }
    exchangeNudgeHandler(setState, goForward);
  };

  if (
    (type === 'reason-based' &&
      (!nudgeConfig.returns_reason_nudge_active || !selectedReasonNudge)) ||
    (type === 'summary' &&
      (!nudgeConfig.return_summary_nudge_active ||
        position !== nudgeConfig.nudge_position))
  ) {
    return null;
  }

  if (
    (type === 'reason-based' && selectedReasonNudge?.nudge_type === 'IMAGE') ||
    (type === 'summary' && nudgeConfig?.nudge_type === 'IMAGE')
  ) {
    return (
      <div
        role="button"
        onClick={onNudgeClick}
        tabIndex={0}
        onKeyDown={onNudgeClick}
        className={`overflow-hidden rounded ${className ?? ''}`}
        style={{
          backgroundImage: `url('${
            type === 'summary'
              ? nudgeConfig.nudge_image_url
              : selectedReasonNudge.nudge_image_url
          }')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 100,
          backgroundPosition: 'center'
        }}
      />
    );
  }

  return (
    <div
      role="button"
      onClick={onNudgeClick}
      tabIndex={0}
      onKeyDown={onNudgeClick}
      className={`d-flex justify-between pd-15 items-center rounded ${className ??
        ''}`}
      style={{ background: nudgeConfig.nudge_background_color }}
    >
      <div className="fs-12" style={{ color: nudgeConfig.nudge_text_color }}>
        {type === 'summary'
          ? nudgeConfig.nudge_message
          : selectedReasonNudge.nudge_message}
      </div>
      <div
        className="rounded-pill pd-10 pd-left-10 pd-right-10 bd-1 fs-12 whitespace-nowrap"
        style={{
          borderColor: nudgeConfig.nudge_text_color,
          color: nudgeConfig.nudge_text_color
        }}
      >
        {nudgeConfig.nudge_cta}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  exchangeNudgeHandler: (setState, goForward) =>
    dispatch(exchangeNudgeHandler(setState, goForward))
});

Nudge.propTypes = {
  tracking: PropTypes.any,
  type: PropTypes.oneOf(['reason-based', 'summary']),
  position: PropTypes.oneOf(['TOP', 'BOTTOM']),
  selectedReason: PropTypes.string,
  exchangeNudgeHandler: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(Nudge);
