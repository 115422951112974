import { useCallback, useEffect, useState } from 'react';

export default () => {
  const [uiState, setUiState] = useState({
    conHeight: '100%',
    footer: 0,
    header: 0,
    body: 0
  });

  const resizeCallback = useCallback(function(entries) {
    entries.forEach(value => {
      uiState[value.target.tagName.toLowerCase()] =
        value.borderBoxSize[0].blockSize;
      if (value.target.tagName.toLowerCase() === 'body') {
        uiState.bodyWidth = value.borderBoxSize[0].inlineSize;
      }
    });
    uiState.conHeight =
      uiState.body - (uiState.header + uiState.footer + (uiState.div ?? 0));
    setUiState({ ...uiState });
  }, []);

  useEffect(() => {
    let observer = new ResizeObserver(resizeCallback);
    if (document.getElementsByTagName('body')?.length > 0) {
      observer.observe(document.getElementsByTagName('body')[0]);
    }

    if (document.getElementsByTagName('header')?.length > 0) {
      observer.observe(document.getElementsByTagName('header')[0]);
    }
    if (document.getElementsByTagName('footer')?.length > 0) {
      observer.observe(document.getElementsByTagName('footer')[0]);
    }
    if (document.getElementById('return-page-message'))
      observer.observe(document.getElementById('return-page-message'));
    return () => {
      observer.disconnect();
      observer = null;
    };
  }, []);
  return uiState;
};
