/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Input from '../../../staticComponents/input';
import { connect } from 'react-redux';
import {
  checkPincodeServiceability,
  updateBankDetail,
  updateUPIDetails,
  resetBankDetail,
  resetUPIDetails,
  fetchIfscDetail,
  updateStoreCreditInfo,
  toggleBankFormValidation,
  selectRefundMethod,
  updateLimitedBankDetail
} from '../../../service';
import dompurify from 'dompurify';
import { strings } from '../../../utils/localizedStrings';
import {
  bankFormValidation,
  limitedBankFormValidation
} from '../../exchange/forms/validate';
import { bankDetailNotRequiredForAdminDomains } from '../../exchange/defaultValues';
import { refundPriceBreakupMappings } from '../../../utils/entity_mapping';
import { newRefundTypes, refundTypeCodes } from '../defaultValues';

// subdomain list for showing additional refund information
export const subdomains = [
  'abfrl-test',
  'peterengland',
  'abfrl-test2',
  'pantaloons',
  'allensolly',
  'louisphilippe',
  'louisphilippe-test',
  'vanheusenindia',
  'vanheusenintimates',
  'aeo',
  'thecollective',
  'forever21',
  'planetfashion',
  'abof',
  'simoncarter',
  'reebok'
];

const refundAmountHidden = [
  'koskii',
  'vhi-test',
  'allensolly-test',
  'shantanunikhil',
  'rforrabbit',
  'koskii-test',
  ...subdomains
];

export const noBackToSrcDomains = [
  'headsupfortails',
  'sunohstudio',
  'thecosmicbyte',
  'wearyourwords',
  'plumgoodness',
  'crocodile'
];

class BankDetailForm extends Component {
  state = {
    name: '',
    accountNumber: '',
    ifsccode: '',
    bankName: '',
    branchName: '',
    upiName: '',
    upiId: '',
    showRefundBreakup: false,
    errors: {}
  };

  _handleChange = e => {
    const { udfKeys } = this.props.tracking.theme;
    const { bankDetail } = this.props;
    bankDetail[e.target.name] = e.target.value;
    if (e.target.name === 'ifsccode') {
      bankDetail.bankName = '';
      bankDetail.branchName = '';
    }
    if (!udfKeys?.returnsGetShortBankDetails) {
      this.props.updateBankDetail(bankDetail);
    } else {
      this.props.updateLimitedBankDetail(bankDetail);
      const errorsObj = limitedBankFormValidation(bankDetail);
      this.setState({ errors: errorsObj });
      if (Object.keys(errorsObj)?.length > 0) {
        this.props.toggleBankFormValidation(true);
      }
    }
  };

  formatInput = e => {
    const { bankDetail } = this.props;
    bankDetail[e.target.name] = e.target.value.trim();
    this.setState({ bankDetail });
    this.props.updateBankDetail(bankDetail);
  };

  _handleChangeUPIForm = e => {
    const { upiDetails } = this.props;
    upiDetails[e.target.name] = e.target.value;
    this.props.updateUPIDetails(upiDetails);
  };

  _selectChange = e => {
    const { upiDetails } = this.props;
    upiDetails.upiName = e;
    this.setState({ upiDetails });
    this.props.updateUPIDetails(upiDetails);
  };

  formatUPIInputs = e => {
    const { upiDetails } = this.props;
    upiDetails[e.target.name] = e.target.value.trim();
    this.setState({ upiDetails });
    this.props.updateUPIDetails(upiDetails);
  };

  fetchIfscDetail = () => {
    const { bankDetail } = this.props;
    if (bankDetail.ifsccode && bankDetail.ifsccode.length === 11) {
      this.props.fetchIfscDetail(bankDetail.ifsccode).then(res => {
        if (res.hasOwnProperty('BRANCH')) {
          bankDetail.bankName = res.BANK;
          bankDetail.branchName = res.BRANCH;
          this.props.updateBankDetail(bankDetail);
        }
        // set errors
        this.setState({ errors: bankFormValidation(bankDetail) });
      });
    } else {
      // set errors
      this.setState({ errors: bankFormValidation(bankDetail) });
    }
  };

  handleCreditCheckBox = () => {
    const { storeCredit } = this.props.orderInfo;
    storeCredit.refundAsCredit = !storeCredit.refundAsCredit;
    storeCredit.allInCredit = null;
    this.props.updateStoreCreditInfo(storeCredit);
  };
  handleCreditRadio = refundName => {
    let {
      storeCredit,
      isErrorInCOD,
      confirmedRefundPayment
    } = this.props.orderInfo;
    storeCredit.allInCredit = null;
    confirmedRefundPayment = refundName;
    isErrorInCOD = false;

    if (
      [refundTypeCodes.bankDetails, refundTypeCodes.upiDetails].includes(
        refundName
      )
    ) {
      isErrorInCOD = true;
    }

    if ([refundTypeCodes.credits].includes(refundName)) {
      storeCredit.allInCredit = true;
    }

    if (
      refundTypeCodes.bankDetails === refundName &&
      bankDetailNotRequiredForAdminDomains.includes(
        this.props.user.subdomain
      ) &&
      this.props.page.source.toLowerCase() === 'dashboard'
    ) {
      isErrorInCOD = false;
    }

    this.props.updateStoreCreditInfo(storeCredit);
    this.props.resetBankDetail();
    this.props.toggleBankFormValidation(isErrorInCOD);
    this.props.selectRefundMethod(confirmedRefundPayment);
  };

  componentWillUnmount = () => {
    this.props.resetBankDetail();
    this.props.resetUPIDetails();
  };

  renderBankDetailForm = () => {
    const { lang } = this.props.user;
    const { udfKeys } = this.props.tracking.theme;
    return (
      <div className="row">
        {/* <div className={`col-md-24 col-xs-24 mrg-btm-10 ${this.props.orderInfo.storeCredit.allInCredit === false ? 'hide' : ''}`}>
        {strings[lang].bankDetail}
      </div> */}
        {!udfKeys?.returnsGetShortBankDetails && (
          <div
            className={`col-md-24 col-xs-24 mrg-btm-10 ${
              this.props.orderInfo.storeCredit.allInCredit === false
                ? 'mrg-top-10'
                : ''
            }`}
          >
            <div className="label">
              {strings[lang].accountHolderName}
              <span className="mandatory">
                <strong>*</strong>
              </span>
            </div>
            <Input
              value={this.props.bankDetail.name}
              name="name"
              className="form-control text-uppercase"
              onChange={this._handleChange}
              maxlength={200}
              onBlur={this.formatInput}
            />
            {this.state?.errors?.name && (
              <span className=" text-red">{this.state?.errors?.name}</span>
            )}
          </div>
        )}
        <div className="col-md-24 col-xs-24 mrg-btm-10">
          <div className="label">
            {!udfKeys?.returnsGetShortBankDetails
              ? strings[lang].accountNumber
              : strings[lang].iban}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={this.props.bankDetail.accountNumber}
            name="accountNumber"
            className="form-control"
            onChange={this._handleChange}
            maxlength={udfKeys?.returnsGetShortBankDetails ? 24 : 40}
            minlength={udfKeys?.returnsGetShortBankDetails ? 6 : 24}
            onBlur={this.formatInput}
            type="password"
          />
          {this.state?.errors?.accountNumber && (
            <span className=" text-red">
              {this.state?.errors?.accountNumber}
            </span>
          )}
        </div>
        <div className="col-md-24 col-xs-24 mrg-btm-10">
          <div className="label">
            {!udfKeys?.returnsGetShortBankDetails
              ? strings[lang].verifyAccountNumber
              : strings[lang].iban}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={this.props.bankDetail.verifyAccountNumber}
            name="verifyAccountNumber"
            className="form-control"
            onChange={this._handleChange}
            maxlength={udfKeys?.returnsGetShortBankDetails ? 24 : 40}
            minlength={udfKeys?.returnsGetShortBankDetails ? 6 : 24}
            onBlur={this.formatInput}
          />
          {this.state?.errors?.verifyAccountNumber && (
            <span className=" text-red">
              {this.state?.errors?.verifyAccountNumber}
            </span>
          )}
        </div>
        {!udfKeys?.returnsGetShortBankDetails && (
          <>
            <div className="col-md-12 col-xs-14 mrg-btm-10">
              <div className="label">
                {strings[lang].ifscCode}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </div>
              <Input
                value={this.props.bankDetail.ifsccode}
                name="ifsccode"
                className="form-control text-uppercase"
                onChange={this._handleChange}
                rows={3}
                maxlength={11}
                required
                onBlur={this.formatInput}
              />
              {this.state?.errors?.ifsccode && (
                <span className=" text-red">
                  {this.state?.errors?.ifsccode}
                </span>
              )}
            </div>
            <div className="col-md-12 col-xs-10 mrg-btm-10 pd-top-20 text-right">
              <button
                className="ant-button ghost min-width-75 xs-min-width-60"
                onClick={this.fetchIfscDetail}
                disabled={this.props.bankDetail.ifsccode.length < 1}
              >
                {strings[lang].fetch} & Verify
              </button>
            </div>
          </>
        )}
        <div className="col-md-24 col-xs-24 mrg-btm-10">
          <div className="label">
            {strings[lang].bankName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={this.props.bankDetail.bankName}
            name="bankName"
            className="form-control"
            maxlength={40}
            readOnly={!udfKeys?.returnsGetShortBankDetails}
            onChange={this._handleChange}
          />
          {this.state?.errors?.bankName && (
            <span className=" text-red">{this.state?.errors?.bankName}</span>
          )}
        </div>
        {!udfKeys?.returnsGetShortBankDetails && (
          <div className="col-md-24 col-xs-24 mrg-btm-10">
            <div className="label">
              {strings[lang].branchName}
              <span className="mandatory">
                <strong>*</strong>
              </span>
            </div>
            <Input
              value={this.props.bankDetail.branchName}
              name="branchName"
              className="form-control"
              maxlength={40}
              readOnly
              onChange={this._handleChange}
            />
          </div>
        )}
        <div className="col-md-24 col-xs-24 mrg-btm-10 mrg-top-5">
          ✅ I confirm my bank account details are correct and accurate
        </div>
      </div>
    );
  };

  renderUPIForm = () => {
    const { lang } = this.props.user;
    return (
      <div className="row">
        <div className={`col-md-24 col-xs-24 mrg-btm-10 mrg-top-10`}>
          <div className="label">
            {strings[lang].upiName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Select
            value={this.props.upiDetails.upiName}
            name="name"
            onChange={this._selectChange}
            options={this.props?.tracking?.theme?.meta_data?.upi_options ?? []}
            placeholder="Select..."
            className="custom-select"
            classNamePrefix="custom-select"
            simpleValue
          />
          <div className="label mrg-top-10">
            {strings[lang].upiId}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={this.props.upiDetails.upiId}
            name="upiId"
            className="form-control"
            onChange={this._handleChangeUPIForm}
            maxlength={40}
            onBlur={this.formatUPIInputs}
          />
        </div>
      </div>
    );
  };

  RenderHtml = () => {
    const [state, setState] = useState({
      selectedMethod: ''
    });
    const { orderInfo, tracking, user } = this.props;
    const {
      cod_options,
      prepaid_options,
      show_refund_options_as_config = false
    } = this.props.returnConfig.refundConfig;
    const { lang } = user;
    const { meta_data, udfKeys } = tracking.theme;
    const refundTypeObj = newRefundTypes(
      lang,
      brandCreditLabelText ??
        strings[lang].brandCredits(
          tracking.theme.brandName,
          (brandCreditText ? brandCreditText : '') ?? ''
        )
    );
    const sanitizer = dompurify.sanitize;

    const {
      orderType,
      confirmedRefundPayment,
      request,
      enterpriseChargesConfig
    } = orderInfo;
    const brandCreditText = tracking.theme?.meta_data?.udf?.filter(
      item => item.key === 'brandCreditText'
    )?.[0]?.value;
    let customRefundMessage = tracking.theme?.meta_data?.udf?.filter(
      item => item.key === 'customRefundMessage'
    )?.[0]?.value;
    if (lang === 'ar' && udfKeys?.customRefundMessageAR) {
      customRefundMessage = udfKeys.customRefundMessageAR;
    }
    let brandCreditMessage = udfKeys?.brandCreditMessage ?? null;
    if (lang === 'ar' && udfKeys?.brandCreditMessageAR) {
      brandCreditMessage = udfKeys.brandCreditMessageAR;
    }
    let brandCreditLabelText = udfKeys?.brandCreditLabelText ?? null;
    if (lang === 'ar' && udfKeys?.brandCreditLabelTextAR) {
      brandCreditLabelText = udfKeys.brandCreditLabelTextAR;
    }
    const brandCreditBelowText = tracking.theme?.meta_data?.udf?.filter(
      item => item.key === 'brandCreditBelowText'
    )?.[0]?.value;

    const {
      capture_bank_detail: bankDetailEnabled,
      store_credit_enabled: storeCreditEnabled,
      capture_upi_detail: upiDetailsEnabled,
      enable_payout_link: payoutLinkEnabled,
      enable_discount_coupon: discountCouponEnabled,
      enable_gift_card: giftCardEnabled
    } = meta_data;

    let options = orderType === 'COD' ? cod_options : prepaid_options;

    if (orderInfo?.isExchangeForwardOrder) {
      options = cod_options;
    }
    if (!show_refund_options_as_config) {
      options = [];
    }

    let methodsAllowed = [];

    if (orderType === 'COD') {
      if (bankDetailEnabled) methodsAllowed.push(refundTypeObj.bankDetails);

      if (storeCreditEnabled) methodsAllowed.push(refundTypeObj.credits);

      if (upiDetailsEnabled) methodsAllowed.push(refundTypeObj.upiDetails);
    }
    if (orderType === 'PREPAID') {
      if (
        !(
          orderInfo.storeCredit.totalAmountPaid === 0 &&
          orderInfo.storeCredit.totalCredit > 0
        ) &&
        !noBackToSrcDomains.includes(user.subdomain)
      ) {
        methodsAllowed.push(refundTypeObj.source);
      }
      if (storeCreditEnabled) methodsAllowed.push(refundTypeObj.credits);
      if (upiDetailsEnabled) methodsAllowed.push(refundTypeObj.upiDetails);
    }

    if (payoutLinkEnabled) {
      methodsAllowed.push(refundTypeObj.REFUND_LINK);
    }
    if (discountCouponEnabled) {
      methodsAllowed.push(refundTypeObj.DISCOUNT_CODE);
    }
    if (giftCardEnabled) {
      methodsAllowed.push(refundTypeObj.GIFT_CARD);
    }

    if (options?.length > 0) {
      methodsAllowed = options;
    }

    if (
      methodsAllowed.length > 0 &&
      !methodsAllowed
        .map(method => method.refund_name)
        .includes(state.selectedMethod)
    ) {
      state.selectedMethod = methodsAllowed[0].refund_name;
      setState({ ...state });
      this.handleCreditRadio(methodsAllowed[0].refund_name);
    }

    const onCheck = (e, refund_name) => {
      setState({ selectedMethod: refund_name });
      this.handleCreditRadio(refund_name);
    };

    if (methodsAllowed.length === 0) {
      return null;
    }

    const returnReasonIds = Object.values(request || {}).map(
      item => item?.reasonId?.value
    );

    const {
      return_charges_type,
      return_charges,
      returns_charges_data,
      applicable_on
    } = enterpriseChargesConfig || {};

    const returnChargesAmount =
      return_charges_type === 'ENTERPRISE_WIDE'
        ? return_charges
        : returns_charges_data?.find(reason =>
            returnReasonIds.includes(reason.reason_id)
          )?.charges ?? 0;

    const returnCharges =
      applicable_on === 'SHIPMENT_LEVEL'
        ? returnChargesAmount
        : (return_charges_type === 'ENTERPRISE_WIDE'
            ? returnReasonIds
            : returnReasonIds.filter(id =>
                returns_charges_data?.some(reason => reason.reason_id === id)
              )
          )?.length * returnChargesAmount;

    const { priceBreakup, priceBreakupParts } = refundPriceBreakupMappings(
      orderInfo,
      this.props.returnConfig.refundConfig,
      returnCharges,
      state.selectedMethod
    );
    const originalPaymentMode = methodsAllowed
      ?.filter(item => item.name === 'Refund to original payment mode')
      .map(i => i.refund_name);

    return (
      <div className="col-md-24 col-xs-24">
        {!udfKeys.hideRefundAmountTile && (
          <section className="bank-form-container">
            {!(methodsAllowed.length === 1 && state.selectedMethod !== '') && (
              <>
                <p className="label text-black">
                  {strings[lang].refundTxt.totalAsCreditNew}
                  <span className="text-red">*</span>
                </p>

                {methodsAllowed.map(method => (
                  <div key={method.refund_name} className="inputGroup left">
                    <input
                      id={method.refund_name}
                      name="refundMethod"
                      className="ndr-checkbox-call"
                      onClick={e => onCheck(e, method.refund_name)}
                      type="radio"
                      value={method.refund_name}
                      defaultChecked={
                        confirmedRefundPayment === method.refund_name ||
                        state.selectedMethod === method.refund_name
                      }
                    />
                    <label
                      className="pre-text trans review"
                      htmlFor={method.refund_name}
                      role="presentation"
                    >
                      {method.text}
                    </label>
                    {state.selectedMethod === method.refund_name &&
                      method.subtext && (
                        <p
                          className={`mrg-left-30 p-3 rounded fs-14 text-white ${
                            method.adjustmentAmount > 0
                              ? 'bg-sucess-dark'
                              : 'bg-warning'
                          }`}
                        >
                          {method.subtext}
                        </p>
                      )}
                    {method.refund_name === refundTypeCodes.credits &&
                      brandCreditBelowText && (
                        <p className="mrg-left-30 fs-14 text-gray">
                          {brandCreditBelowText}
                        </p>
                      )}
                  </div>
                ))}
              </>
            )}
            {state.selectedMethod && (
              <>
                {state.selectedMethod === refundTypeCodes.bankDetails && (
                  <section>
                    {orderInfo.storeCredit.allInCredit === false &&
                    methodsAllowed.includes('credits') ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(
                            strings[lang].refundTxt.cod(
                              orderInfo?.refundAutomationEnabled
                                ? priceBreakup.total
                                : orderInfo.storeCredit.totalAmountPaid,
                              tracking.theme.brandName
                            )
                          )
                        }}
                      ></div>
                    ) : (
                      <p className="mrg-btm-10">{strings[lang].bankDetail}</p>
                    )}
                    {this.renderBankDetailForm()}
                  </section>
                )}
                {state.selectedMethod === refundTypeCodes.upiDetails && (
                  <section>
                    <p className="mrg-btm-10">{strings[lang].upiDetails}</p>
                    {this.renderUPIForm()}
                  </section>
                )}
                {[refundTypeCodes.source].includes(state.selectedMethod) && (
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          customRefundMessage ??
                            strings[lang].refundTxt.defaultPrepaid(
                              refundAmountHidden.indexOf(user.subdomain) > -1
                                ? ''
                                : orderInfo.storeCredit.totalAmountPaid > 0 &&
                                  methodsAllowed.find(
                                    method =>
                                      method.refund_name ===
                                      refundTypeCodes.credits
                                  )
                                ? orderInfo.storeCredit.totalAmountPaid -
                                  (returnCharges ?? 0)
                                : orderInfo.totalAmountPaid !== 0
                                ? orderInfo.totalAmountPaid
                                : orderInfo.totalRefundAmount,
                              refundAmountHidden.indexOf(user.subdomain) > -1
                                ? ''
                                : orderInfo?.currency ?? '',
                              tracking?.theme?.brandName
                            )
                        )
                      }}
                    ></p>
                    {this.renderRefundHtml()}
                  </section>
                )}
                {state.selectedMethod === refundTypeCodes.GIFT_CARD && (
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          strings[lang].refundTxt.refundTotalAsGiftCard(
                            orderInfo?.refundAutomationEnabled
                              ? priceBreakup.total
                              : orderInfo.storeCredit.totalAmountPaid > 0
                              ? orderInfo.storeCredit.totalAmountPaid
                              : orderInfo.totalRefundAmount +
                                orderInfo.storeCredit.totalCredit,
                            refundAmountHidden.indexOf(user.subdomain) > -1
                              ? ''
                              : orderInfo?.currency ?? '',
                            tracking.theme.brandName
                          )
                        )
                      }}
                    ></p>
                  </section>
                )}
                {state.selectedMethod === originalPaymentMode[0] && (
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          strings[lang].refundTxt.refundOriginalPaymentMode(
                            orderInfo?.refundAutomationEnabled
                              ? priceBreakup.total
                              : orderInfo.storeCredit.totalAmountPaid > 0
                              ? orderInfo.storeCredit.totalAmountPaid
                              : orderInfo.totalRefundAmount +
                                orderInfo.storeCredit.totalCredit,
                            refundAmountHidden.indexOf(user.subdomain) > -1
                              ? ''
                              : orderInfo?.currency ?? ''
                          )
                        )
                      }}
                    ></p>
                  </section>
                )}
                {state.selectedMethod === refundTypeCodes.credits && (
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          brandCreditMessage ??
                            strings[lang].refundTxt.refundTotalAsCredit(
                              orderInfo?.refundAutomationEnabled
                                ? priceBreakup.total
                                : orderInfo.storeCredit.totalAmountPaid > 0
                                ? orderInfo.storeCredit.totalAmountPaid
                                : orderInfo.totalRefundAmount +
                                  orderInfo.storeCredit.totalCredit,
                              tracking.theme.brandName
                            ) +
                              ' ' +
                              (udfKeys.brandCreditMessageSuffix ?? '')
                        )
                      }}
                    ></p>
                    {this.renderRefundHtml()}
                  </section>
                )}
              </>
            )}
            {orderInfo.refundAutomationEnabled ? (
              <>
                <div
                  onClick={() =>
                    this.setState({
                      showRefundBreakup: !this.state.showRefundBreakup
                    })
                  }
                  className="text-blue fs-14 mrg-top-5 mrg-btm-5 cursor-pointer"
                >
                  {this.state.showRefundBreakup ? 'Hide' : 'Show'} Refund Amount
                  Breakup
                  <img
                    src={'/images/chevron_up.svg'}
                    style={
                      this.state.showRefundBreakup
                        ? {}
                        : {
                            transform: 'rotate(180deg)'
                          }
                    }
                    alt="expand_less"
                    className="mrg-left-5"
                  />
                </div>
                {this.state.showRefundBreakup ? (
                  <div className="bg-light-grey pd-top-5">
                    {priceBreakupParts.map(eachPart => (
                      <div
                        key={eachPart.label}
                        className="fs-12 fw-600 d-flex justify-between pd-left-10 pd-right-10 mrg-btm-5"
                      >
                        <span className="text-secondary-color">
                          {eachPart.label}
                        </span>
                        <span className="text-secondary-color">
                          {eachPart.prefix} {priceBreakup[eachPart.value]}
                        </span>
                      </div>
                    ))}
                    {returnCharges > 0 && (
                      <div className="fs-12 fw-600 d-flex justify-between pd-left-10 pd-right-10 mrg-btm-5">
                        <span className="text-secondary-color">
                          Return Charge
                        </span>
                        <span className="text-secondary-color">
                          - {returnCharges.toFixed(2)}
                        </span>
                      </div>
                    )}
                    <div className="fs-12 fw-700 d-flex justify-between pd-left-10 pd-right-10 bg-grey pd-5">
                      <span>Total</span>
                      <span>{priceBreakup.total}</span>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            {udfKeys?.returnSummaryInstructions ? (
              <p
                className={`${
                  this.state.showRefundBreakup ? 'pd-top-10' : ''
                } pd-left-5`}
              >
                {udfKeys?.returnSummaryInstructions}
              </p>
            ) : null}
          </section>
        )}
      </div>
    );
  };

  renderRefundHtml = (onlyCredit = false) => {
    const { user, orderInfo, tracking } = this.props;
    if (subdomains.indexOf(user.subdomain) === -1) {
      return null;
    }

    if (onlyCredit) {
      return (
        <>
          <p className="mrg-top-10">
            Amount added to credits cannot be encashed
          </p>
          <p className="mrg-top-10">
            Refund will be transferred to {tracking.theme.brandName} Credits
            within 2 business days after product is picked up.
          </p>
        </>
      );
    }

    switch (orderInfo.confirmedRefundPayment) {
      case refundTypeCodes.source:
        return (
          <p className="mrg-top-10">
            Refund will be transferred to Original payment source with in 2
            business days after product is picked up.
          </p>
        );
      case refundTypeCodes.credits:
        return (
          <>
            <p className="mrg-top-10">
              Amount added to credits cannot be encashed
            </p>
            <p className="mrg-top-10">
              Refund will be transferred to {tracking.theme.brandName} Credits
              within 2 business days after product is picked up.
            </p>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return <this.RenderHtml />;
  }
}

BankDetailForm.contextTypes = {
  router: PropTypes.object.isRequired
};

BankDetailForm.propTypes = {
  className: PropTypes.string,
  bankDetail: PropTypes.object,
  upiDetails: PropTypes.object,
  orderInfo: PropTypes.object,
  tracking: PropTypes.object,
  user: PropTypes.object,
  checkPincodeServiceability: PropTypes.func,
  updateBankDetail: PropTypes.func,
  updateUPIDetails: PropTypes.func,
  resetBankDetail: PropTypes.func,
  resetUPIDetails: PropTypes.func,
  fetchIfscDetail: PropTypes.func,
  updateStoreCreditInfo: PropTypes.func,
  toggleBankFormValidation: PropTypes.func,
  selectRefundMethod: PropTypes.func,
  page: PropTypes.any,
  updateLimitedBankDetail: PropTypes.func,
  returnConfig: PropTypes.object
};

const mapStateToProps = state => ({
  bankDetail: state.orderInfo.bankDetail,
  upiDetails: state.orderInfo.upiDetails,
  orderInfo: state.orderInfo,
  tracking: state.tracking,
  user: state.user,
  page: state.page,
  returnConfig: state.returnConfig
});

const mapDispatchToProps = dispatch => ({
  checkPincodeServiceability: query =>
    dispatch(checkPincodeServiceability(query)),
  updateBankDetail: query => dispatch(updateBankDetail(query)),
  resetBankDetail: () => dispatch(resetBankDetail()),
  updateUPIDetails: query => dispatch(updateUPIDetails(query)),
  resetUPIDetails: () => dispatch(resetUPIDetails()),
  fetchIfscDetail: query => dispatch(fetchIfscDetail(query)),
  updateStoreCreditInfo: query => dispatch(updateStoreCreditInfo(query)),
  toggleBankFormValidation: query => dispatch(toggleBankFormValidation(query)),
  selectRefundMethod: confirmedRefundPayment =>
    dispatch(selectRefundMethod(confirmedRefundPayment)),
  updateLimitedBankDetail: query => dispatch(updateLimitedBankDetail(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailForm);
