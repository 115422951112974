import toast from 'react-hot-toast';
import { APIs } from '../../../../utils/apis';
import { post } from '../../../../utils/httpRequests';

const loadScript = src => {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
const showRazorpay = async ({
  amount,
  source,
  onSuccess,
  onFailure,
  onOpen,
  queryParam,
  options
}) => {
  try {
    const query = new URLSearchParams(queryParam);

    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      toast.error('Something wrong happened. Please try again later.');
      return;
    }
    let result;
    try {
      // creating a new order
      result = await post({
        url: `${APIs.PAYMENTS.CREATE_ORDER_NS}?${query.toString()}`,
        method: 'POST',
        data: {
          amount,
          source,
          internal_identifier: options.internal_identifier
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.data.meta.status !== 200) {
        onOpen();
        toast.error('Something wrong happened. Please try again later.');
        return;
      }
    } catch {
      onOpen();
      toast.error('Something wrong happened. Please try again later.');
      return;
    }
    // console.log('result.data', result.data);
    // Getting the order details back
    const { public_key, payment_id } = result.data.result;
    const checkoutOptions = {
      key: public_key, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: options.currency ?? 'INR',
      name: options.enterprise,
      description: options.description,
      image: options.logo,
      order_id: payment_id,
      handler: async function(response) {
        const data = {
          payment_id,
          checkout_payment_id: response.razorpay_payment_id,
          checkout_order_id: response.razorpay_order_id,
          checkout_signature: response.razorpay_signature
        };

        // console.log('data', data);
        try {
          const result = await post({
            url: `${APIs.PAYMENTS.VERIFY_ORDER_NS}?${query.toString()}`,

            method: 'POST',
            data,
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (result.data.meta.status === 200) {
            onSuccess({ payment_id });
          } else {
            onFailure();
          }

          // logging response to slack

          const blocks = [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `report time: ${new Date().toLocaleString()}`
              }
            },
            {
              type: 'rich_text',
              elements: [
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Razor pay logs'
                    }
                  ]
                },
                {
                  type: 'rich_text_preformatted',
                  elements: [
                    {
                      type: 'text',
                      text: JSON.stringify({
                        razorPayResponse: response
                      })
                    },
                    {
                      type: 'text',
                      text: JSON.stringify({
                        orderCreationData: {
                          amount,
                          source,
                          internal_identifier: options.internal_identifier
                        },

                        public_key,
                        payment_id
                      })
                    }
                  ],
                  border: 0
                }
              ]
            }
          ];

          await post({
            url: `${APIs.slackAlert}`,
            method: 'POST',
            data: {
              channel: '#alerts-return-exchange-payments-logs', // slack channel
              blocks
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }).catch(() => {
            console.log('slack alert failed');
          });
        } catch {
          onFailure();
        }
      },
      prefill: options.prefill,
      // {
      //   name: 'Anwin Joselyn',
      //   email: 'anwin.joselyn@clickpost.in',
      //   contact: '9999999999'
      // },
      theme: {
        color: options.themeColor
      }
    };
    const paymentObject = new window.Razorpay(checkoutOptions);
    paymentObject.on('payment.failed', function() {
      onFailure();
    });

    paymentObject.open();
    onOpen();
  } catch (error) {
    onFailure(error);
  }
};

export { showRazorpay };
