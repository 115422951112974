import React from 'react';
import PropTypes from 'prop-types';

import { currencyMapper } from '../../utils/entity_mapping';
import { strings } from '../../utils/localizedStrings';

const MultipleShipments = ({
  shipments = [],
  meta_data,
  lang = 'en',
  country_code = ''
}) => {
  return (
    <>
      {shipments && shipments.length > 0 ? (
        shipments.map((shipment, i) => (
          <div
            key={i}
            className={`col-md-24 col-xs-24 content mrg-btm-10 bd-btm-1`}
          >
            <div className="row">
              <div className="col-md-24 col-xs-24">
                <div className="item-thumbnail">
                  {shipment?.product_url ? (
                    <a
                      href={shipment.product_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          shipment.images ||
                          (meta_data.image_placeholder ?? '/images/apparel.svg')
                        }
                        alt="product"
                      />
                    </a>
                  ) : (
                    <img
                      src={
                        shipment.images ||
                        (meta_data.image_placeholder ?? '/images/apparel.svg')
                      }
                      alt="product"
                    />
                  )}
                </div>
                <div className="item-info three-q-width">
                  <div className="item-productName">
                    {shipment.description ?? ''}
                  </div>
                  <div className="size-block">
                    <span className="label">SKU </span>
                    <span className="label">{shipment.sku ?? ''}</span>
                  </div>
                  <div className="size-block">
                    <span className="label">
                      {strings[lang]?.qty ?? 'Qty'}{' '}
                    </span>{' '}
                    <span className="label">{shipment.quantity ?? 0}</span>
                  </div>
                  <div className="size-block">
                    {currencyMapper(country_code ?? '')} {shipment.price ?? 0}
                  </div>
                  <p>{strings[lang].allPriceShownPerItem}</p>
                  {shipment.order_cancelled ? (
                    <div className="size-block txt-red">
                      {strings[lang].placeFreshPickupRequest}
                    </div>
                  ) : null}
                  {shipment.non_return_reason ? (
                    <div className="size-block txt-red">
                      {shipment.non_return_reason}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>Unable to load shipments</div>
      )}
    </>
  );
};

MultipleShipments.propTypes = {
  shipments: PropTypes.array,
  meta_data: PropTypes.object,
  lang: PropTypes.string,
  country_code: PropTypes.string
};

export default MultipleShipments;
