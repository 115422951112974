const actionMessages = {
  addressForm: {
    missingFields:
      'There is some issue in selection, please edit Reverse Pickup Address first',
    missingFieldsSA: 'There is some issue in selected Reverse Pickup Address'
  },
  address: {
    blank: 'Address cannot be blank or less than 10 character.'
  },
  pincode: {
    blank: 'Please enter a 6 digit pincode.',
    short: 'Please enter 6 digit pincode.',
    notServiceable: 'Pincode is not serviceable, please ship order to us.',
    serviceable: 'Selected pincode is serviceable',
    checking: 'Checking pincode serviceability',
    sg: 'Please enter a 5-6 digit postal code.',
    my: 'Please enter a 5 digit postal code.'
  },
  landmark: {
    blank:
      'Please provide a landmark for courier partner to locate your address.',
    short: 'Landmark cannot be blank or less than 5 character.'
  },
  area: {
    blank: 'Please select area'
  },
  preferredDate: {
    blank: 'Please enter a vaild date.'
  },
  comment: {
    blank: 'Comment cannot be blank.',
    short: 'Please enter at least 10 characters and no more than 600.'
  },
  phone: {
    blank: 'Please enter a 10 digit valid phone number.'
  },
  email: {
    blank: 'Please enter a valid email Id.'
  },
  checkbox: {
    unchecked: 'Please choose any option.'
  },
  inProgress: 'Action in progress..',
  submittedConcern: function(courierPartner) {
    return (
      'We will followup with ' + courierPartner + ' to process your request.'
    );
  },
  submittedQuickConcern:
    'Sometimes courier partners update their status incorrectly due to technical glitches. \
    We have created an issue to deliver your shipment at the earliest.',
  rating: {
    good: 'Thanks for your feedback! Look forward to serving you again.',
    bad:
      'Thanks for the feedback! We will work on providing you a better experience next time!',
    empty: 'Rating cannot be empty'
  },
  edd: {
    case1: {
      a: 'Arriving Soon: Reached the Destination Hub',
      b: 'Moving as Expected',
      c: 'Shipment Not Moving - Logistics Team Notified'
    },
    case2: {
      a: 'Failed Delivery: Reattempt to be made',
      b: 'Shipment Delayed: Courier Partner Notified'
    }
  },
  return: {
    login: subdomain =>
      ({
        flomattress:
          "Sorry! We don't recognize these details. Please check and retry. If you're still facing issues, contact us at support@flomattress.com."
      }[subdomain] ??
      "We don't seem to recognize that order id or mobile number/emailId. Please check and try again."),
    submit: "We processed your request, you'll get an email soon.",
    bankDetail: 'Please fill bank detail properly.',
    UPIDetails: 'Please fill all the UPI details.',
    error:
      'There is some error with this service, please contact our customer support'
  },
  updateContact:
    'Thanks for the update!, we will start sending notifications on updated contact number.',
  selfShip: 'Self Ship is not allowed, please contact customer support.',
  noShipping: 'Pincode is not serviceable.',
  shipmentNotExists: `The order/shipment you're trying to track has not been shipped yet or the Tracking ID/Order ID entered is incorrect.`,
  noImage: 'Please attach supporting images to proceed next',
  noItemForReturn:
    'There are no items eligible for return, please check your My Orders section for order / return summary'
};

export default actionMessages;
