/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useExchangeContext } from '../wrapper';
import { stepActions, steps } from '../defaultValues';
import {
  addProduct,
  fetchIfscDetail,
  updateBankDetail,
  updateUPIDetails,
  updateExchangeInfo
} from '../../../service';
import { getCatalogueExchangeTotal } from '../utils/helper';
import toast from 'react-hot-toast';
import Instructions from '../components/Instructions';
import {
  bankFields,
  bankFormValidation,
  fieldLabels,
  upiFormValidation
} from './validate';
import Input from '../components/Input';
import { useForm } from '../utils/useForm';
import { strings } from '../../../utils/localizedStrings';

const typeData = {
  payment: {
    heading: 'Payment mode',
    inputName: 'paymentMode'
  },
  refund: {
    heading: 'Refund mode',
    inputName: 'refundMode'
  }
};

const paymentOptions = {
  COD: { text: 'Cash on Delivery', code: 'COD' },
  PREPAID: { text: 'Online', code: 'PREPAID' }
};

const ExchangePayments = ({
  orderInfo,
  exchangeConfig,
  updateExchangeInfo,
  tracking,
  user,
  ...props
}) => {
  const { goBack, goForward, step } = useExchangeContext();
  const { udfKeys } = tracking.theme;
  const { lang } = user;
  const { totalDiffSign } = getCatalogueExchangeTotal(
    orderInfo,
    exchangeConfig
  );
  const type = totalDiffSign === 1 ? 'payment' : 'refund';
  const { catalogueConfig = {} } = exchangeConfig;
  typeData.refund.options =
    orderInfo.orderType === 'PREPAID'
      ? catalogueConfig?.prepaid_refund_options
      : catalogueConfig.cod_refund_options;

  typeData.payment.options =
    catalogueConfig.order_types?.map(type => paymentOptions[type]) ?? [];

  const [state, setState] = useState(null);

  const bankDetailsForm = useForm({
    init: { ...orderInfo.bankDetail },
    validation: bankFormValidation
  });
  const upiDetailsForm = useForm({
    init: { ...orderInfo.upiDetails },
    validation: upiFormValidation
  });
  const onChange = value => {
    setState(value);
  };

  const fetchIfscDetail = () => {
    if (
      bankDetailsForm.formData.ifsccode &&
      bankDetailsForm.formData.ifsccode.length === 11
    ) {
      props.fetchIfscDetail(bankDetailsForm.formData.ifsccode).then(res => {
        if (res.hasOwnProperty('BRANCH')) {
          bankDetailsForm.onChange('bankName', res.BANK);
          bankDetailsForm.onChange('branchName', res.BRANCH);
        }
      });
    }
  };

  const onSubmit = () => {
    if (!state) return toast.error('Please select a payment mode');
    switch (type) {
      case 'payment':
        updateExchangeInfo({ paymentMode: state });
        break;
      case 'refund':
        updateExchangeInfo({ refundMode: state });
        break;
    }
    if (state === 6 && type === 'refund') {
      bankDetailsForm.handleSubmit(() => {
        props.updateBankDetail(bankDetailsForm.formData);
        goForward(steps.SUMMARY);
      })();
    } else if (state === 7 && type === 'refund') {
      upiDetailsForm.handleSubmit(() => {
        props.updateUPIDetails(upiDetailsForm.formData);
        goForward(steps.SUMMARY);
      })();
    } else {
      return goForward(steps.SUMMARY);
    }
  };

  useEffect(() => {
    if (totalDiffSign === 0) {
      switch (step.action) {
        case stepActions.FORWARD:
          return goForward(steps.SUMMARY);
        case stepActions.REVERSE:
          return goBack(steps.DATE);
      }
    }

    switch (type) {
      case 'payment':
        setState(orderInfo.exchange.paymentMode);
        break;
      case 'refund':
        setState(orderInfo.exchange.refundMode);
        break;
    }
  }, []);

  return (
    <Layout
      heading={typeData[type]?.heading}
      goback={() => goBack(steps.DATE)}
      buttons={[{ label: 'Next', onClick: onSubmit }]}
      border
    >
      <div className="d-flex flex-col justify-between ht-100">
        <div className="xs-pd-10 pd-20">
          {/* radio button code starts */}
          {typeData[type]?.options?.map(method => (
            <label
              htmlFor={typeData[type]?.inputName}
              key={method.code}
              role="presentation"
              onClick={() => onChange(method.code)}
              className={`cursor-pointer d-flex bd-1 ${
                method.code === state ? 'border-theme' : 'border-trans'
              } pd-15 mrg-btm-10 rounded justify-between`}
            >
              <div className="d-flex items-center">
                <label className="radio-input small" style={{ marginTop: 0 }}>
                  <input
                    className="mrg-right-10 bg-brand-secondary"
                    type="radio"
                    name={typeData[type]?.inputName}
                    required
                    checked={method.code === state}
                    value={method.code}
                    onChange={() => onChange(method.code)}
                  />
                </label>
                <div>
                  <div className="fs-16 fw-600 text-primary-color">
                    {method.text}
                  </div>
                  {/* <div className="fs-12 text-secondary-color">{method.sub}</div> */}
                  {/* <div className="fs-14 text-secondary-color">
                  {address.address}
                </div> */}
                </div>
              </div>
            </label>
          ))}
          {/* radio button code ends */}

          {state === 6 && (
            <div className="grid-container space-10">
              {bankFields.map(field => (
                <div key={field} className="d-flex">
                  <Input
                    label={strings[lang][fieldLabels[field]]}
                    name={field}
                    wrapperClassName={'full-width'}
                    id={field}
                    value={bankDetailsForm.formData[field]}
                    type={field === 'verifyAccountNumber' ? 'password' : 'text'}
                    onChange={e =>
                      bankDetailsForm.onChange(field, e.target.value)
                    }
                    readOnly={['bankName', 'branchName'].includes(field)}
                    error={bankDetailsForm.errors[field]}
                  />
                  {field === 'ifsccode' && (
                    <button
                      disabled={bankDetailsForm.formData?.ifsccode?.length < 1}
                      onClick={fetchIfscDetail}
                      className="ant-button mrg-left-5 mrg-top-20"
                    >
                      Fetch Bank Details
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
          {state === 7 && (
            <div className="grid-container space-10">
              <div className="label">
                {strings[lang].upiName}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </div>
              <Select
                value={upiDetailsForm.formData.upiName}
                name="name"
                onChange={value => upiDetailsForm.onChange('upiName', value)}
                options={tracking?.theme?.meta_data?.upi_options ?? []}
                placeholder="Select..."
                className="custom-select"
                classNamePrefix="custom-select"
                simpleValue
              />
              {upiDetailsForm.errors?.upiName && (
                <div className="fs-12 text-red">
                  {upiDetailsForm.errors?.upiName}
                </div>
              )}
              <div className="label mrg-top-10">
                {strings[lang].upiId}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </div>
              <Input
                value={upiDetailsForm.formData.upiId}
                name="upiId"
                onChange={e => upiDetailsForm.onChange('upiId', e.target.value)}
                maxlength={40}
                error={upiDetailsForm.errors?.upiId}
              />
            </div>
          )}
        </div>
        {udfKeys.exchangeRefundInstructions && type === 'refund' && (
          <div className="xs-pd-10 pd-20">
            <Instructions description={udfKeys.exchangeRefundInstructions} />
          </div>
        )}
      </div>
    </Layout>
  );
};

ExchangePayments.propTypes = {
  orderInfo: PropTypes.any,
  exchangeConfig: PropTypes.any,
  addProduct: PropTypes.func,
  updateExchangeInfo: PropTypes.func,
  tracking: PropTypes.any,
  fetchIfscDetail: PropTypes.func,
  updateBankDetail: PropTypes.func,
  updateUPIDetails: PropTypes.func,
  user: PropTypes.any
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo,
  exchangeConfig: state.exchangeConfig,
  tracking: state.tracking,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  addProduct: data => dispatch(addProduct(data)),
  updateExchangeInfo: data => dispatch(updateExchangeInfo(data)),
  fetchIfscDetail: ifsc => dispatch(fetchIfscDetail(ifsc)),
  updateBankDetail: data => dispatch(updateBankDetail(data)),
  updateUPIDetails: data => dispatch(updateUPIDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangePayments);
