/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { strings } from '../../../utils/localizedStrings';
import toast from 'react-hot-toast';
import {
  allowedImageTypes,
  currencyMapper
} from '../../../utils/entity_mapping';
import {
  addImageExchange,
  addProduct,
  fetchVariants,
  ipAddressHandler,
  preSignedUrlExchange,
  quantityChange,
  removeImageExchange,
  resetImagesExchange,
  uploadFileToS3Exchange,
  uploadImageConfigExchange,
  imageUploadHandler,
  categoryBasedReasonsGetter
} from '../../../service';
import {
  headsupfortails,
  validateRequest
  // commentMandatorySubdomain
} from './validate';
// import { commonTextRegex } from '../../../utils/helper';
import Nudge from '../Nudge';
import { useExchangeContext } from '../wrapper';
import {
  exchangeTypes,
  pumaSubdomains,
  onlyReturnModalText,
  selectVariantIn,
  steps
} from '../defaultValues';
import { useForm } from '../utils/useForm';
import PageHeading from '../components/PageHeading';
import { PageSpinner } from '../../../staticComponents/spinner';
import { noPriceSubdomains, useVariants } from './variants';
import Instructions from '../components/Instructions';

export const infoIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill="#000"
    />
  </svg>
);

export const exchangeIcon = subdomain => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 2.5V0L3.33333 3.33333L6.66667 6.66667V4.16667C9.425 4.16667 11.6667 6.40833 11.6667 9.16667C11.6667 10.0083 11.4583 10.8083 11.0833 11.5L12.3 12.7167C12.95 11.6917 13.3333 10.475 13.3333 9.16667C13.3333 5.48333 10.35 2.5 6.66667 2.5ZM6.66667 14.1667C3.90833 14.1667 1.66667 11.925 1.66667 9.16667C1.66667 8.325 1.875 7.525 2.25 6.83333L1.03333 5.61667C0.383333 6.64167 0 7.85833 0 9.16667C0 12.85 2.98333 15.8333 6.66667 15.8333V18.3333L10 15L6.66667 11.6667V14.1667Z"
      fill={
        subdomain === '82e'
          ? 'var(--base-primary-color)'
          : 'var(--base-border-color)'
      }
    />
  </svg>
);

const SelectComponent = props => {
  const { error } = props;
  return (
    <>
      <Select
        {...props}
        onBlur={event => event.preventDefault()}
        className="custom-select text-primary-color bg-brand-secondary mrg-btm-5"
        classNamePrefix="custom-select"
      />
      {error && (
        <span className=" text-red">
          {error ?? 'This is a mandatory field'}
        </span>
      )}
    </>
  );
};
const TextareaComponent = props => {
  const { error } = props;
  return (
    <>
      <textarea
        {...props}
        onBlur={event => event.preventDefault()}
        className={`full-width text-primary-color bg-brand-secondary ant-input${
          props.hidden ? ' hide' : ''
        }`}
      />
      {error && <span className=" text-red">This is a mandatory field</span>}
    </>
  );
};

let Reasons = ({
  user,
  orderInfo,
  reasons,
  selectedProdId,
  variants,
  fetchVariants,
  file,
  exchangeConfig,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    returnReasons: [],
    exchangeReasons: []
  });

  const {
    labels: { exchangeTexts, returnTexts },
    step,
    goBack,
    goForward
  } = useExchangeContext();
  const {
    state: variantState,
    setState: setVariantState,
    variantList,
    submitHandler
  } = useVariants({
    addProduct: props.addProduct,
    orderInfo,
    currentStep: step.current === steps.REASON,
    tracking: props.tracking,
    user,
    variants
  });
  const { udfKeys } = props.tracking.theme;
  const { formData, reset, onChange, errors, handleSubmit } = useForm({
    init: { reasonId: null, subreason: '', comment: '', exchangeType: '' },
    validation: validateRequest({ udfKeys, user })
  });

  const queryParams = new URLSearchParams(props.location.search);

  const selectedProd = orderInfo.request[selectedProdId] ?? {};
  const selectedProduct =
    orderInfo.products.find(item => item.id === selectedProdId) ?? {};

  const isExchange = selectedProd?.type === 'exchange';

  const isMultiExchangeEnabled =
    props.tracking?.theme?.meta_data?.exchange?.allowMultiExchange;
  const exchangeOptions =
    exchangeConfig?.reasonsWithSubReasons?.find(
      reason => reason.value === formData?.reasonId?.value
    )?.exchangeOptions ?? [];

  const isSameProdAvailable =
    selectedProduct.exchangeTypesAvailable?.includes(
      exchangeTypes.SAME_PRODUCT_SAME_VARIANT
    ) && exchangeOptions.includes(exchangeTypes.SAME_PRODUCT_SAME_VARIANT);
  const isVariantAvailable =
    selectedProduct.exchangeTypesAvailable?.includes(
      exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
    ) &&
    (exchangeOptions.includes(exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT) ||
      pumaSubdomains.includes(user.subdomain));
  const isDifferentProdAvailable =
    exchangeConfig.catalogueConfig?.service_active &&
    exchangeOptions.includes(exchangeTypes.DIFFERENT_PRODUCT);

  useEffect(() => {
    const productId =
      orderInfo.products
        .filter(product => product.id === selectedProdId)
        .map(i => i.iteminfoextended__product_id) ?? {};

    if (selectedProd) {
      ['reasonId', 'subreason', 'comment'].map(fieldName => {
        if (selectedProd[fieldName])
          onChange(fieldName, selectedProd[fieldName]);
      });
      fetchVariants(selectedProdId);
    }

    if (selectedProd && productId && exchangeConfig?.categoryBasedReason) {
      categoryBasedReasonsGetter(productId, user.subdomain).then(res => {
        if (
          res?.returnReasons?.list?.length > 0 ||
          res.exchangeReasons?.length > 0
        ) {
          return setState({
            ...state,
            returnReasons: res.returnReasons.list,
            exchangeReasons: res.exchangeReasons
          });
        }
      });
    } else {
      return setState({
        ...state,
        exchangeReasons: exchangeConfig.reasonsWithSubReasons,
        returnReasons: reasons.list
      });
    }
  }, [step.current === steps.REASON]);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function() {
      toast.error(strings[user.lang].invalidImageErr);
    };
  };

  const fileRender = e => {
    const { lang, subdomain } = user;
    if (e.target.files.length > 0) {
      let imageFile = e.target.files[0];
      let { type } = imageFile;
      if (allowedImageTypes.indexOf(type) > -1) {
        imageUploadHandler(
          imageFile,
          subdomain,
          props.addImage,
          getBase64,
          orderInfo,
          selectedProdId
        );
      } else {
        toast.error(strings[lang].invalidImageErr);
      }
    }
  };

  const quantityChange = (_e, method) => {
    const { tracking } = props;
    if (tracking.theme.meta_data && tracking.theme.meta_data.quantity_lock) {
      return;
    }
    const { request } = orderInfo;
    const eligibleQuantityKey = isExchange
      ? 'eligible_quantity_can_be_exchange'
      : 'eligible_quantity_can_be_return';

    if (selectedProd?.quantity) {
      if (
        method === 'add' &&
        selectedProd?.quantity < selectedProd[eligibleQuantityKey]
      ) {
        request[selectedProdId].quantity += 1;
      }
      if (method === 'sub' && selectedProd?.quantity > 1) {
        request[selectedProdId].quantity -= 1;
      }
    }
    props.quantityChange(request);
    if (
      udfKeys.selectVariantIn === selectVariantIn.reasons &&
      isVariantAvailable &&
      isExchange &&
      request[selectedProdId].quantity >
        variantState?.variant?.inventory_quantity
    ) {
      setVariantState({
        variant: '',
        error: 'No inventory, Please select another size'
      });
    }
  };
  const commentCount = formData?.comment?.length ?? 0;

  const returnExchangeImageText = (imageCount, brandName) =>
    ({
      miraggiolife: `(Please add at least ${imageCount} image(s) along with the tag in the requested specifications)
      NOTE: Please make sure that the tag is attached to the product`,
      theater: `(Please add ${imageCount} images, covering top, side, front, and bottom views of the product. Max image size is 1MB per image)`,
      louisstitchre: `(Please upload Images of the product and packaging box along with an invoice copy)`,
      birkenstock: `(Please add atleast ${imageCount} image(s) in the requested specifications. Share images of front and back side of sole.)`,
      Zouk: `Kindly upload ${imageCount} views of the product you want to exchange/return. (Front view, Back view, Top view, Bottom view, Left view, and right view)`
    }[brandName] ??
    `(Please add atleast ${imageCount} image(s) in the requested specifications)`);

  const uploadImage = idx => {
    return props.preSignedUrl(idx, selectedProdId).then(res => {
      if (res.meta.status === 200) {
        return props.uploadFileToS3(idx, selectedProdId);
      }
    });
  };

  const goBackAndReset = () => {
    const { request } = orderInfo;
    if (
      !request[selectedProdId].reasonId ||
      (isExchange && !isMultiExchangeEnabled) ||
      (isExchange &&
        [
          exchangeTypes.DIFFERENT_PRODUCT,
          exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
        ].includes(request[selectedProdId].exchangeType) &&
        !request[selectedProdId].selected_variant)
    ) {
      delete request[selectedProdId];
      props.addProduct(request);
      props.resetImages(selectedProdId);
    }
    goBack(steps.ITEM_SELECTION);
  };

  const onSubmit = async () => {
    let { request } = orderInfo;
    const { ipAddress } = props;
    let error = null;
    const imgUploadToast = toast.loading('Uploading Image(s)...');
    const isError = await Promise.all(
      file[selectedProdId].list.map((_file, idx) => uploadImage(idx))
    ).then(imgResp => {
      if (!imgResp?.every(resp => resp?.status === 204)) {
        toast.error('Image upload failed please try again!', {
          id: imgUploadToast
        });
        return true;
      }
      const selectedProduct = orderInfo.products.find(
        product => product.id === Number(selectedProdId)
      );
      if (formData.exchangeType === 'SAME_PRODUCT_SAME_VARIANT') {
        let variantTitle =
          variants?.list?.find(
            item => Number(item.id) === Number(selectedProd?.thisProdVariant)
          )?.title ?? '';
        request[selectedProdId].exchangePrice = selectedProduct.price;
        request[selectedProdId].variant_id =
          selectedProduct.iteminfoextended__variant_id;
        request[selectedProdId].selected_variant = variantTitle;
        request[selectedProdId].previous_variant = variantTitle;
        request[selectedProdId].exchangeSku =
          variants.list.find(
            variant =>
              variant.id ===
              Number(selectedProduct.iteminfoextended__variant_id)
          ).sku ?? '';
      }

      request[selectedProdId] = {
        ...request[selectedProdId],
        ...formData,
        type: selectedProd.type
      };
      if (request[selectedProdId].type === 'refund') {
        delete request[selectedProdId].exchangeType;
      }

      toast.dismiss(imgUploadToast);
      let variantInfo = {};
      if (
        formData.exchangeType ===
          exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT &&
        udfKeys.selectVariantIn === selectVariantIn.reasons
      ) {
        variantInfo = submitHandler();
        if (variantInfo.error) {
          return true;
        }
        request[selectedProdId] = {
          ...request[selectedProdId],
          ...variantInfo
        };
      }

      props.addProduct(request);
      return false;
    });
    if (
      !ipAddress.isLoaded &&
      exchangeConfig.catalogueConfig.higher_price_item_only
    ) {
      error = await props.ipAddressHandler();
      if (error) {
        toast.error('Something went wrong, please try again');
      }
    }

    if (isError || error) {
      return;
    }
    if (
      formData.exchangeType === exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
    ) {
      if (udfKeys.selectVariantIn === selectVariantIn.reasons) {
        return isMultiExchangeEnabled
          ? goForward(steps.ITEM_SELECTION)
          : goForward(steps.ADDRESS);
      }

      return goForward(steps.VARIANT_EXCHANGE);
    }
    if (formData.exchangeType === exchangeTypes.DIFFERENT_PRODUCT) {
      return goForward(steps.PRODUCT_EXCHANGE);
    }

    if (
      request[selectedProdId].type === 'exchange' &&
      !isMultiExchangeEnabled
    ) {
      return goForward(steps.ADDRESS);
    }

    goForward(steps.ITEM_SELECTION);
  };

  const preSubmit = () => {
    setIsOpen(false);

    if (
      selectedProd.type === 'exchange' &&
      ((['82e', 'theindusvalley', 'vguard'].includes(user.subdomain) &&
        !isSameProdAvailable) ||
        (!isSameProdAvailable &&
          !isVariantAvailable &&
          !isDifferentProdAvailable))
    ) {
      return goBackAndReset();
    }

    if (
      file?.[selectedProdId]?.list.length <
        (file?.[selectedProdId]?.imageQuantity ?? 0) &&
      queryParams.get('source') !== 'dashboard'
    ) {
      return toast.error('Please Add Images to Continue!');
    }

    onSubmit();
  };

  const userReasons = () => {
    if (state.returnReasons?.length > 0) {
      if (queryParams.get('source') !== 'dashboard') {
        return state.returnReasons?.filter(item => {
          const reason = item.label.toLowerCase();
          return !reason.includes('admin');
        });
      } else {
        return state.returnReasons;
      }
    }
  };

  const filterReasonId = () => {
    if (state.exchangeReasons.includes(formData.reasonId)) {
      return formData?.reasonId?.label;
    } else {
      return state.exchangeReasons[0]?.label;
    }
  };

  const formElArray = [
    <div key={'reasonsFormEl'} className="pd-5 mrg-top-10 d-flex flex-col">
      <div className="col-md-24 mrg-btm-15">
        <div className="text-secondary-color">Reason</div>
        <SelectComponent
          name={'reasonId'}
          value={formData.reasonId}
          onChange={val => {
            onChange('reasonId', val);
            props.uploadImageConfig(val, selectedProdId);
            onChange('subreason', '');
          }}
          options={isExchange ? state.exchangeReasons : userReasons()}
          error={errors.reasonId}
        />
      </div>
      {!isExchange && selectedProd.shipment_eligible_for_exchange && (
        <div className="col-md-24">
          <Nudge
            type="reason-based"
            selectedReason={(formData?.reasonId && filterReasonId()) ?? ''}
            onClick={reset}
            className="mrg-btm-15"
          />
        </div>
      )}
      {udfKeys.selectVariantIn === selectVariantIn.reasons &&
      isVariantAvailable &&
      isExchange ? (
        <div className="col-md-24 mrg-btm-15">
          <div className="text-secondary-color">
            Select {udfKeys.variantLabel ?? 'Variant'}
          </div>
          <SelectComponent
            name={'variant'}
            value={variantState.variant}
            onChange={val =>
              setVariantState({
                ...variantState,
                variant: val,
                error: undefined
              })
            }
            options={variantList}
            components={{
              SingleValue: props =>
                noPriceSubdomains.includes(user.subdomain)
                  ? `${props.data.title}`
                  : `${props.data.title} - ${orderInfo.currency ??
                      currencyMapper(
                        props?.tracking?.theme?.config?.country_code
                      )} ${props.data.price}`
            }}
            className="custom-select text-primary-color"
            classNamePrefix="custom-select"
            error={variantState.error}
          />
        </div>
      ) : null}
      {!headsupfortails.includes(user.subdomain) &&
        formData?.reasonId?.subReasons?.length > 0 && (
          <div className="col-md-24 mrg-btm-15">
            <div className="text-secondary-color">Sub Reason</div>
            <SelectComponent
              name={'subreason'}
              value={formData.subreason}
              onChange={val => {
                onChange('subreason', val);
              }}
              options={formData?.reasonId?.subReasons}
              error={errors.subreason}
            />
          </div>
        )}
      <div className="col-md-24 mrg-btm-15">
        <div className="text-secondary-color">
          Comment {udfKeys?.commentMandatory ? '' : '(optional)'}
        </div>
        <TextareaComponent
          name={'comment'}
          value={formData.comment}
          onChange={e => {
            onChange(e.target.name, e.target.value);
          }}
          maxLength="200"
          // pattern={commonTextRegex[user.lang]}
          placeholder="Max. 200 characters"
          error={errors.comment}
          rows={'3'}
        />
        <p className="pull-right">{200 - commentCount} characters left</p>
      </div>
      {file[selectedProdId]?.imageQuantity > 0 && (
        <>
          <div className="col-md-24">
            <div className="mrg-btm-15">
              Images{' '}
              {file[selectedProdId]?.imageQuantity > 0 &&
              queryParams.get('source') !== 'dashboard'
                ? udfKeys.imageUploadNote ?? returnExchangeImageText(
                    file[selectedProdId]?.imageQuantity,
                    props?.tracking?.theme?.brandName
                  )
                : '(optional)'}
            </div>
            <div className="md-flex xs-flex">
              {file[selectedProdId]?.list.map((item, i) => (
                <div
                  key={i}
                  className="uploaded-square-image-container exchange"
                >
                  <img src={item.base64} alt={item.name} />
                  <span
                    className="icon-close"
                    onClick={() => props.removeImage(i, selectedProdId)}
                  />
                </div>
              ))}
              {file[selectedProdId]?.list.length < 6 ? ( // hardcoded how many images can be uploaded max
                <>
                  <label className="file-input exchange">
                    <input
                      type="file"
                      className="fileInput"
                      name="fileInput"
                      onChange={fileRender}
                      accept="image/*,image/heic,image/heif"
                    />
                    <span className="icon-plus fs-12" />
                  </label>
                </>
              ) : null}
            </div>
          </div>
          {['82e'].includes(user.subdomain) && (
            <div className="col-xs-24 mrg-top-15 mrg-btm-20">
              <div className="bd-primary bg-brand-primary mr bg-light pd-15 md-flex xs-flex rounded">
                <div>
                  <div className="mrg-right-10">{infoIcon(user.subdomain)}</div>
                </div>
                {/* <div> */}
                {/* <div className="fw-600 mrg-btm-5 text-primary-color">
      {isExchange ? 'Exchange' : 'Return'} Instructions :
    </div> */}
                <ul className="text-secondary-color ul-no-pd">
                  <li>
                    The front image of the product bottle along with the name{' '}
                  </li>
                  <li> The back image of the product</li>
                  <li> Image of the barcode on the product</li>
                  <li> Image of the outer box of the product</li>
                </ul>
                {/* </div> */}
              </div>{' '}
            </div>
          )}
        </>
      )}
      {udfKeys.exchangeReasonsInstructions && isExchange && (
        <div className="mt-auto col-md-24">
          <Instructions description={udfKeys.exchangeReasonsInstructions} />
        </div>
      )}
    </div>,
    <div
      key={'productEl'}
      className="pd-20 bg-brand-secondary bg-light md-flex xs-flex justify-between"
    >
      <div className="md-flex xs-flex">
        <img
          className="mrg-right-10 object-fit-cover rounded"
          alt="product"
          width="70"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/images/img-placeholder.svg';
          }}
          height="70"
          src={selectedProd?.images || '/images/img-placeholder.svg'}
        />
        <div className="fs-12">
          <div className="fs-16 fw-600">{selectedProd?.description}</div>
          <div>
            Price :{' '}
            {orderInfo.currency ??
              currencyMapper(props.tracking.theme.config.country_code)}{' '}
            {selectedProd?.price}
          </div>
        </div>
      </div>
      <div>
        <div className="mrg-btm-10 text-primary-color">
          {isExchange ? exchangeTexts?.[1] : 'Return'} Qty:
        </div>
        <div className="return-quantity bd-primary-color">
          <div className="dec" onClick={e => quantityChange(e, 'sub')}>
            <span className="icon-minus" />
          </div>
          <div className="text">{selectedProd?.quantity}</div>
          <div className="inc" onClick={e => quantityChange(e, 'add')}>
            {' '}
            <span className="icon-plus" />
          </div>
        </div>
      </div>
    </div>
  ];

  const exchangeTypesEl = {
    [exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT]: (
      <label
        className={`radio-input small ${!exchangeOptions.includes(
          'SAME_PRODUCT_DIFFERENT_VARIANT'
        ) && 'hide'} ${
          !isVariantAvailable ? 'disable-element' : ''
        } mrg-right-10`}
      >
        <input
          name="exchangeType"
          value={exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT}
          checked={
            formData.exchangeType ===
            exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
          }
          disabled={!isVariantAvailable}
          type="radio"
          required
          onChange={e => onChange(e.target.name, e.target.value)}
        />
        <div>
          <p className="fw-600 fs-16 mrg-btm-5 text-primary-color">
            {udfKeys?.exchangeTypeVariantHeading ?? 'Size/color Exchange'}
          </p>
          <p className="text-secondary-color mrg-btm-5 fs-14">
            {udfKeys?.exchangeTypeVariantBody ??
              'Didn’t like the current size/color of your product? Click “Yes, Continue” to see different size/colors available for this product'}
          </p>
          {!isVariantAvailable && (
            <p className="text-red fs-14">
              No Variants for this product present at the moment
            </p>
          )}
        </div>
      </label>
    ),
    [exchangeTypes.DIFFERENT_PRODUCT]: (
      <label
        key={exchangeTypes.DIFFERENT_PRODUCT}
        className={`radio-input small fs-16 mrg-right-10${
          isDifferentProdAvailable ? '' : ' hide'
        }
    ${!exchangeOptions.includes(exchangeTypes.DIFFERENT_PRODUCT) && 'hide'} 
    
    `}
      >
        <input
          name="exchangeType"
          value={exchangeTypes.DIFFERENT_PRODUCT}
          // disabled={variantDisabled}
          type="radio"
          onChange={e => onChange(e.target.name, e.target.value)}
          required
        />
        <div>
          <p className="fw-600 fs-16 mrg-btm-5 text-primary-color">
            {udfKeys.exchangeTypeProductHeading ?? 'Style Exchange'}
          </p>
          <p className="text-secondary-color fs-14">
            {udfKeys.exchangeTypeProductBody ??
              `If you’re not satisfied with your product, Click “Yes, Continue” to exchange it for another product of your choice`}
            {/* It seems you were dissatisfied with the product you
        received! By clicking next, you can exchange it for a
        new product of lesser or equivalent value.{' '} */}
          </p>
        </div>
      </label>
    ),
    [exchangeTypes.SAME_PRODUCT_SAME_VARIANT]: (
      <label
        key={exchangeTypes.SAME_PRODUCT_SAME_VARIANT}
        className={`radio-input small fs-18 mrg-right-10 mrg-btm-10 ${!exchangeOptions.includes(
          'SAME_PRODUCT_SAME_VARIANT'
        ) && 'hide'} ${!isSameProdAvailable ? ' disable-element' : ''}`}
      >
        <input
          name="exchangeType"
          value={exchangeTypes.SAME_PRODUCT_SAME_VARIANT}
          required
          type="radio"
          onChange={e => onChange(e.target.name, e.target.value)}
        />
        <div>
          <p className="fw-600 fs-16 mrg-btm-5 text-primary-color">
            {udfKeys?.exchangeTypeSameHeading ?? 'Exchange same item?'}
          </p>
          <p className="text-secondary-color mrg-btm-5 fs-14">
            {udfKeys?.exchangeTypeSameBody ??
              'By clicking next, you are indicating your interest in a replacement item as the item received by you was damaged or not the promised quality'}
          </p>
          {!isSameProdAvailable && (
            <p className="text-red fs-14">
              No inventory present for this product at the moment
            </p>
          )}
        </div>
      </label>
    )
  };

  const exchangeTypesElKeysSort = () =>
    Object.keys(exchangeTypesEl).sort((exchangeType1, exchangeType2) => {
      if (
        exchangeType1 === exchangeTypes.SAME_PRODUCT_SAME_VARIANT &&
        !isSameProdAvailable
      ) {
        return 1;
      }
      if (
        exchangeType1 === exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT &&
        !isVariantAvailable
      ) {
        return 1;
      }
      if (
        exchangeType1 === exchangeTypes.DIFFERENT_PRODUCT &&
        !isDifferentProdAvailable
      ) {
        return 1;
      }
      if (
        exchangeType2 === exchangeTypes.SAME_PRODUCT_SAME_VARIANT &&
        !isSameProdAvailable
      ) {
        return -1;
      }
      if (
        exchangeType2 === exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT &&
        !isVariantAvailable
      ) {
        return -1;
      }
      if (
        exchangeType2 === exchangeTypes.DIFFERENT_PRODUCT &&
        !isDifferentProdAvailable
      ) {
        return -1;
      }

      return 0;
    });

  return (
    <form onSubmit={handleSubmit(preSubmit)} className="ht-100 d-flex flex-col">
      <PageHeading
        goback={goBackAndReset}
        heading={
          udfKeys.selectVariantIn === selectVariantIn.reasons && isExchange
            ? 'Exchange'
            : `Why do you want to ${
                isExchange ? exchangeTexts[0] : returnTexts[0]
              }?`
        }
      />
      {variants.isFetching ? (
        <div className="col-xs-24 col-md-24 ">
          <PageSpinner />
        </div>
      ) : (
        <>
          <div className="ht-100 reasons overflow-x-hidden xs-bd-0 bd-primary bd-1 overflow-auto">
            <div
              className={`d-flex flex-col ${
                udfKeys.reasonsLayout === 'reverse' ? '' : 'justify-between'
              } text-secondary-color overflow-y-scroll ht-100`}
              id="exchangeContainer"
            >
              {udfKeys.reasonsLayout === 'reverse'
                ? formElArray.reverse()
                : formElArray}
            </div>
          </div>
          <div className="row mrg-top-15">
            <div className="col-md-24 text-center pd-left-20 pd-right-20">
              <button
                type={isExchange ? 'button' : 'submit'}
                // disabled={submitting || invalid}
                onClick={
                  isExchange
                    ? handleSubmit(() => {
                        if (
                          // this conditions ensures that the modal does not show when there is only variants option present, currently doing this for puma domains only
                          udfKeys.selectVariantIn === selectVariantIn.reasons &&
                          isVariantAvailable &&
                          !isSameProdAvailable
                        ) {
                          onChange(
                            'exchangeType',
                            exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
                          );
                          preSubmit();
                        } else {
                          setIsOpen(true);
                        }

                        if (
                          ['82e', 'theindusvalley', 'vguard'].includes(
                            user.subdomain
                          ) ||
                          selectedProduct.total_variant_inventory_quantity === 0
                        ) {
                          onChange('exchangeType', 'SAME_PRODUCT_SAME_VARIANT');
                        }
                      })
                    : null
                }
                className="ant-button mrg-btm-10 rounded-pill full-width"
              >
                Next
              </button>
              {/* {isExchange ? (
            <div className="text-black md-flex xs-flex items-center justify-center">
              {infoIcon()}{' '}
              <span className="mrg-left-10">
                Exchange with same item allowed
              </span>
            </div>
          ) : null} */}
            </div>
          </div>
        </>
      )}
      {isOpen && (
        <div
          className="modal-overlay"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="md-flex xs-flex flex-col bg-brand-secondary rounded">
              {['82e', 'theindusvalley', 'vguard'].includes(user.subdomain) ||
              (selectedProduct.exchangeTypesAvailable.length === 0 &&
                !isDifferentProdAvailable) ? (
                <div className="md-flex xs-flex pd-20">
                  <div className="mrg-btm-10 mrg-right-10">
                    {exchangeIcon(user.subdomain)}
                  </div>
                  <div className="mrg-btm-10">
                    <div
                      className={`fw-600 md-flex xs-flex justify-between mrg-btm-5`}
                    >
                      <span
                        className={`${
                          !isSameProdAvailable
                            ? ' text-red'
                            : ' text-primary-color'
                        }`}
                      >
                        {!isSameProdAvailable
                          ? udfKeys.onlyReturnModalHeading ??
                            'Item can only be Returned'
                          : `${exchangeTexts?.[0]} same item`}
                      </span>
                      <span
                        className="icon-close fs-12"
                        role="presentation"
                        onClick={() => setIsOpen(false)}
                      />
                    </div>
                    <div className="mrg-btm-20 text-secondary-color">
                      {!isSameProdAvailable
                        ? onlyReturnModalText[user.subdomain]
                          ? onlyReturnModalText[user.subdomain]
                          : udfKeys.onlyReturnModalText ??
                            `This item is out of stock so the exchange is not possible. Kindly proceed if you want to ${
                              Object.keys(orderInfo?.request ?? {})?.length > 1
                                ? 'delete this item and continue with exchange'
                                : 'return this item.'
                            }`
                        : 'By clicking next, you are indicating your interest in a replacement item as the item received by you was damaged or not the promised quality'}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {' '}
                  <div className="md-flex pd-20 xs-flex justify-between">
                    <p className="fs-18 fw-700">
                      {udfKeys.exchangeTypeModalHeading ??
                        'How do you want to Exchange?'}
                    </p>
                    <span
                      className="icon-close cursor-pointer md"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                  <div>
                    <hr className="dashed mrg-0" />
                  </div>
                  <div className="pd-20">
                    {exchangeTypesElKeysSort().map(exchangeType => (
                      <React.Fragment key={exchangeType}>
                        {exchangeTypesEl[exchangeType]}
                      </React.Fragment>
                    ))}
                    <input
                      type="hidden"
                      name="variantTitle"
                      value={
                        variants?.list?.find(
                          item =>
                            Number(item.id) ===
                            Number(selectedProd?.thisProdVariant)
                        )?.title ?? ''
                      }
                    />
                  </div>
                </>
              )}
              {udfKeys?.exchangeTypeModalNote && (
                <p className="pd-right-20 pd-left-20 mrg-left-20 text-red fs-14">
                  {udfKeys?.exchangeTypeModalNote}
                </p>
              )}
              {isSameProdAvailable ^ isVariantAvailable &&
              udfKeys?.exchangeNoStockModalNote ? (
                <p className="pd-right-20 pd-left-20 mrg-left-20 text-red fs-14">
                  {udfKeys?.exchangeNoStockModalNote}
                </p>
              ) : null}
              <div
                className={`full-width pd-20 mrg-top-20 ${
                  (user.subdomain === 'houseofaldeno' &&
                    !isSameProdAvailable &&
                    !isVariantAvailable) ||
                  (!isDifferentProdAvailable &&
                    !isSameProdAvailable &&
                    !isVariantAvailable &&
                    user.subdomain === 'mydesignation')
                    ? 'hide'
                    : ''
                } `}
              >
                <button
                  type="submit"
                  onClick={e => e.stopPropagation()}
                  className="ant-button rounded-pill full-width"
                >
                  {(['82e', 'theindusvalley', 'vguard'].includes(
                    user.subdomain
                  ) &&
                    isSameProdAvailable) ||
                  (!isSameProdAvailable &&
                    !isVariantAvailable &&
                    !isDifferentProdAvailable)
                    ? `${
                        Object.keys(orderInfo?.request ?? {})?.length > 1
                          ? 'Delete this item and continue'
                          : 'Proceed'
                      }`
                    : udfKeys?.exchangeTypeModalSubmit ?? 'Yes, Continue'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

Reasons.propTypes = {
  orderInfo: PropTypes.any,
  reasons: PropTypes.any,
  file: PropTypes.any,
  addImage: PropTypes.func,
  removeImage: PropTypes.func,
  quantityChange: PropTypes.func,
  uploadImageConfig: PropTypes.func,
  selectedProdId: PropTypes.number,
  exchangeConfig: PropTypes.object,
  addProduct: PropTypes.any,
  resetImages: PropTypes.any,
  location: PropTypes.any,
  tracking: PropTypes.any,
  ipAddress: PropTypes.any,
  ipAddressHandler: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  orderInfo: state.orderInfo,
  reasons: state.reasons,
  tracking: state.tracking,
  file: state.file,
  addImage: state.addImage,
  variants: state.variants,
  exchangeConfig: state.exchangeConfig,
  ipAddress: state.ipAddress
});
const mapDispatchToProps = dispatch => ({
  addImage: (query, id) => dispatch(addImageExchange(query, id)),
  removeImage: (idx, id) => dispatch(removeImageExchange(idx, id)),
  quantityChange: query => dispatch(quantityChange(query)),
  uploadImageConfig: (data, id) =>
    dispatch(uploadImageConfigExchange(data, id)),
  preSignedUrl: (idx, id) => dispatch(preSignedUrlExchange(idx, id)),
  uploadFileToS3: (idx, id) => dispatch(uploadFileToS3Exchange(idx, id)),
  fetchVariants: id => dispatch(fetchVariants(id)),
  addProduct: query => dispatch(addProduct(query)),
  resetImages: prodId => dispatch(resetImagesExchange(prodId)),
  ipAddressHandler: () => dispatch(ipAddressHandler())
});

export default connect(mapStateToProps, mapDispatchToProps)(Reasons);
