/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currencyMapper } from '../../../utils/entity_mapping';
import { addProduct, resetImagesExchange } from '../../../service';
import { Link } from 'react-router-dom';
import { useExchangeContext } from '../wrapper';
import { useForm } from '../utils/useForm';
import { validateSelection } from './validate';
import { exchangeTypes, pumaSubdomains, steps } from '../defaultValues';
import { infoIcon } from '../utils/svgs';

export const MyLoader = props => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="15" rx="3" ry="3" width="250" height="12" />
    <rect x="150" y="40" rx="3" ry="3" width="250" height="12" />
    <rect x="10" y="10" width="100" height="120" />
  </ContentLoader>
);
const returnSvg = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.9987 4.16732V0.833984L2.83203 5.00065L6.9987 9.16732V5.83398C9.75703 5.83398 11.9987 8.07565 11.9987 10.834C11.9987 13.5923 9.75703 15.834 6.9987 15.834C4.24036 15.834 1.9987 13.5923 1.9987 10.834H0.332031C0.332031 14.5173 3.31536 17.5007 6.9987 17.5007C10.682 17.5007 13.6654 14.5173 13.6654 10.834C13.6654 7.15065 10.682 4.16732 6.9987 4.16732Z"
      fill="currentColor"
    />
  </svg>
);

const exchangeSvg = () => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 2.5V0L3.33333 3.33333L6.66667 6.66667V4.16667C9.425 4.16667 11.6667 6.40833 11.6667 9.16667C11.6667 10.0083 11.4583 10.8083 11.0833 11.5L12.3 12.7167C12.95 11.6917 13.3333 10.475 13.3333 9.16667C13.3333 5.48333 10.35 2.5 6.66667 2.5ZM6.66667 14.1667C3.90833 14.1667 1.66667 11.925 1.66667 9.16667C1.66667 8.325 1.875 7.525 2.25 6.83333L1.03333 5.61667C0.383333 6.64167 0 7.85833 0 9.16667C0 12.85 2.98333 15.8333 6.66667 15.8333V18.3333L10 15L6.66667 11.6667V14.1667Z"
      fill="currentColor"
    />
  </svg>
);

let ProductSelection = ({
  user,
  orderInfo,
  addProduct,
  tracking,
  resetImages,
  exchangeConfig
}) => {
  const { config, udfKeys } = tracking.theme;

  let { request, allow_future_returns, allow_future_exchanges } = orderInfo;
  const {
    state,
    labels: { exchangeTexts, returnTexts },
    setState,
    goForward,
    step
  } = useExchangeContext();
  const { formData, handleSubmit, onChange, isDirty } = useForm({
    init: { selection: '' },
    validation: validateSelection
  });

  const isOnlyExchangeAllowed =
    tracking.theme.udfKeys?.onlyExchangeAllowed === 'true';

  const requestProdList = Object.values(orderInfo.request);

  const selectedProduct =
    orderInfo.products.find(
      product => product.id === Number(formData.selection)
    ) ?? {};

  const isSameProdAvailable = selectedProduct.exchangeTypesAvailable?.includes(
    exchangeTypes.SAME_PRODUCT_SAME_VARIANT
  );

  const isVariantAvailable = selectedProduct.exchangeTypesAvailable?.includes(
    exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
  );
  const isDifferentProdAvailable =
    exchangeConfig.catalogueConfig?.service_active;

  const urlParams = {
    waybill: orderInfo.awb,
    security_key: orderInfo.shipment_uuid
  };

  const eligibleforExchange = orderInfo?.products?.map(
    item => item.shipment_eligible_for_exchange
  );

  const filterByEligibility = orderInfo?.products?.filter(
    product =>
      product.shipment_eligible_for_exchange ||
      product.shipment_eligible_for_return
  );
  // products where no action can be taken but also no request is created
  const nonActionableProducts = orderInfo?.products?.filter(
    product =>
      !product.shipment_eligible_for_exchange &&
      !product.shipment_eligible_for_return
    // product.eligible_quantity_can_be_exchange > 0 &&
    // product.eligible_quantity_can_be_return > 0
  );

  const productsToDisplay = [...filterByEligibility, ...nonActionableProducts];

  const isMaxDaysCrossed = orderInfo?.products?.every(
    p =>
      p.non_return_reason === 'Return max days have been crossed' &&
      p.non_exchange_reason === 'Exchange max days have been crossed'
  );

  const onEdit = id => {
    setState(prev => ({ ...prev, editProductId: id }));
    goForward(steps.REASON);
  };

  const onNext = () => {
    if (formData.selection && !(formData.selection in request)) {
      return onSubmit(Object.values(orderInfo.request)?.[0]?.type);
    }
    return goForward(steps.ADDRESS);
  };

  const onSubmit = type => {
    setState(prev => ({ ...prev, editProductId: selectedProduct.id }));
    // if (queryParams?.get('awbs')?.split(',')?.length > 1) {
    //   history.push(
    //     `/return-exchange?waybill=${selectedProduct.tracking_info__awb}&security_key=${selectedProduct.tracking_info__shipment_uuid}`
    //   );
    // }
    request = {
      ...request,
      [selectedProduct.id]: {
        type,
        description: selectedProduct.description,
        final_amount_paid: selectedProduct.final_amount_paid,
        images: selectedProduct.images,
        price: selectedProduct.price,
        sku: selectedProduct.sku,
        store_credits_used: selectedProduct.store_credits_used,
        id: selectedProduct.id,
        variant_id: selectedProduct.iteminfoextended__variant_id,
        thisProdVariant: selectedProduct.iteminfoextended__variant_id,
        quantity: 1,
        eligible_quantity_can_be_return:
          selectedProduct.eligible_quantity_can_be_return,
        eligible_quantity_can_be_exchange:
          selectedProduct.eligible_quantity_can_be_exchange,
        shipment_eligible_for_exchange:
          selectedProduct.shipment_eligible_for_exchange,
        price_breakup: selectedProduct.price_breakup,
        discount_price: selectedProduct.discount_price ?? 0,
        tracking_info_id: selectedProduct.tracking_info_id
      }
    };
    addProduct(request);
    goForward(steps.REASON);
  };

  useEffect(() => {
    if (state.editProductId && step.current === steps.ITEM_SELECTION) {
      onChange('selection', state.editProductId);
    }
  }, [step.current === steps.ITEM_SELECTION]);

  const isExchangeDisabled =
    !isDirty ||
    !selectedProduct.shipment_eligible_for_exchange ||
    !allow_future_exchanges ||
    !config.exchangeServiceEnabled ||
    (selectedProduct.isVariantsFetched &&
      ['82e', 'theindusvalley', 'vguard'].includes(user.subdomain) &&
      !isSameProdAvailable) ||
    (selectedProduct.isVariantsFetched &&
      !isSameProdAvailable &&
      !isVariantAvailable &&
      !isDifferentProdAvailable);

  //code copied from return/order.js
  const getIsNItemsSelected = (n = 1) =>
    Object.values(request).length === n &&
    Array.from({ length: n }).every(
      (_, i) => Object.values(request)[i].reasonId
    );

  const getIsNItemsAtATime = product =>
    udfKeys.resrictReturnItems &&
    getIsNItemsSelected(Number(udfKeys.resrictReturnItems)) &&
    !request[product.id];

  const nonReturnExchangeMsg = product => {
    if (
      !product.shipment_eligible_for_exchange &&
      !product.shipment_eligible_for_return
    ) {
      return 'Item cannot be Returned/Exchanged';
    } else if (!product.shipment_eligible_for_exchange) {
      return product.non_exchange_reason;
    } else if (!product.shipment_eligible_for_return) {
      return product.non_return_reason;
    } else if (udfKeys?.resrictReturnItems) {
      // Ensure udfKeys is defined and resrictReturnItems is a property of udfKeys
      return `You can only select ${udfKeys.resrictReturnItems} item at a time`;
    }
    // else {
    //   return 'You have exceeded the number of items to be returned/exchanged at a time';
    // }
  };

  const requestType = Object.values(orderInfo.request)?.[0]?.type;
  
  return (
    <form className="md-flex xs-flex ht-100 justify-between flex-col">
      {orderInfo.isFetching || orderInfo?.products?.length === 0 ? (
        <div className="col-xs-24 col-md-24 ">
          <section className="content preloader bd-top-width-1">
            <MyLoader />
          </section>
        </div>
      ) : (
        <>
          {nonActionableProducts.length === orderInfo.products.length ? null : (
            <div className="mrg-top-20">
              <span className="fs-20 fw-700 text-capitalize">
                {productsToDisplay.length > 0
                  ? `Select an item to ${
                      requestProdList?.[0]?.type === 'exchange'
                        ? exchangeTexts[0]
                        : requestProdList?.[0]?.type
                        ? returnTexts[0]
                        : `${returnTexts[0]} or ${exchangeTexts[0]}`
                    }`
                  : `Item(s) already in ${returnTexts[0]} or ${exchangeTexts[0]}`}
              </span>
            </div>
          )}
          <div>
            {orderInfo?.reference_number ? (
              <div className="mrg-btm-20 mrg-top-20 fw-600 fs-18 col-xs-24 pd-0">
                Order ID : {orderInfo.reference_number}
              </div>
            ) : null}
            {(!allow_future_returns || !allow_future_exchanges) &&
            !requestProdList?.[0]?.type &&
            productsToDisplay.length > 0 ? (
              <div className="d-flex items-center pd-20 bg-light-red rounded mrg-btm-20 mrg-top-20">
                <div className="mrg-right-10 d-flex items-center">
                  {infoIcon('', '#D32F2F')}
                </div>
                <p>
                  {!allow_future_returns ? returnTexts[0] : ''}{' '}
                  {!allow_future_returns && !allow_future_exchanges ? '&' : ''}{' '}
                  {!allow_future_exchanges ? exchangeTexts[0] : ''} is not
                  allowed for this order
                </p>
              </div>
            ) : null}

            {orderInfo.lastReturnDate && udfKeys?.returnExpiresSoon && (
              <div className="d-flex items-center full-width rounded mrg-btm-20 mrg-top-20">
                <div className="mrg-right-10 d-flex items-center">
                  {infoIcon('', '#D32F2F')}
                </div>
                <p className="text-red-900 fs-14">
                  Expires Soon! Return Eligibility Ends on{' '}
                  {moment(new Date(orderInfo.lastReturnDate)).format(
                    'MMMM Do, YYYY'
                  )}
                </p>
              </div>
            )}
            {isMaxDaysCrossed && user.subdomain === 'mydesignation' && (
              <div className="d-flex items-center full-width rounded mrg-btm-20 mrg-top-20">
                <div className="mrg-right-10 d-flex items-center">
                  {infoIcon('', '#D32F2F')}
                </div>
                <p className="text-red-900 fs-14">
                  Your order return/exchange period is crossed. Please get in
                  touch with our support team on +91 8330076677 or
                  <a
                    className="btn-underline mrg-left-5"
                    href="https://api.whatsapp.com/send/?phone=918330076677"
                  >
                    Click Here
                  </a>
                </p>
              </div>
            )}
          </div>
          <div
            style={{
              height: `calc(100% - 56px)`,
              overflowY: 'auto'
            }}
          >
            {' '}
            {productsToDisplay.map(product => {
              const isDisabled =
                (!product.shipment_eligible_for_exchange &&
                  !product.shipment_eligible_for_return) ||
                getIsNItemsAtATime(product) ||
                (requestType === 'exchange' &&
                  !product.shipment_eligible_for_exchange) ||
                (requestType === 'refund' &&
                  !product.shipment_eligible_for_return);
              return (
                <div
                  key={product.id}
                  onClick={
                    isDisabled
                      ? null
                      : () => {
                          if (!(product.id in orderInfo.request)) {
                            onChange(
                              'selection',
                              formData.selection === product.id
                                ? ''
                                : product.id
                            );
                          }
                        }
                  }
                  className={`col-xs-24 ${
                    isDisabled ? 'disabled' : ''
                  } cursor-pointer ${
                    Number(formData.selection) === product.id
                      ? 'border-theme'
                      : ''
                  } space-y-3 ${
                    product.id in orderInfo.request ? 'border-active' : ''
                  } bd-primary rounded no-gutter justify-between bd-1 md-flex  pd-btm-15 pd-top-15 xs-flex`}
                >
                  <div className={`md-flex mrg-0 xs-flex`} style={{ flex: 1 }}>
                    <img
                      src={product?.images || '/images/img-placeholder.svg'}
                      alt="product"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/images/img-placeholder.svg';
                      }}
                      width={100}
                      className="mrg-right-10 min-w-100 object-fit-cover img-rounded"
                      height={100}
                    />

                    <div
                      style={{ width: '100%' }}
                      className="md-flex xs-flex flex-col justify-between"
                    >
                      <div>
                        <div
                          className="text-primary-color fw-600 mrg-btm-5"
                          style={{ wordBreak: 'break-all' }}
                        >
                          {product.description}
                        </div>
                        <div className="text-secondary-color fs-14 mrg-btm-5">
                          SKU {product.sku}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary-color fs-14">
                          {product.id in orderInfo.request ? (
                            <span>
                              Qty{' '}
                              {product.id in orderInfo.request
                                ? `${orderInfo.request[product.id].quantity} of`
                                : ''}{' '}
                              {product.eligible_quantity_can_be_return}
                            </span>
                          ) : (
                            <span>
                              {product.quantity -
                                product.eligible_quantity_can_be_return >
                                0 && (
                                <p className=" mrg-btm-5">
                                  {/* <span className="mrg-left-10 mrg-right-10 text-theme">
                                    |
                                  </span> */}
                                  {pumaSubdomains.includes(user.subdomain) &&
                                  product.returnMode === 'EXCHANGE'
                                    ? 'Exchanged'
                                    : 'Returned'}{' '}
                                  Qty{' '}
                                  {product.quantity -
                                    product.eligible_quantity_can_be_return}
                                </p>
                              )}
                              Qty {product.quantity}
                            </span>
                          )}
                          <span className="mrg-left-10 mrg-right-10 text-theme">
                            |
                          </span>
                          <span className="fw-700">
                            {orderInfo.currency ??
                              currencyMapper(config.country_code)}{' '}
                            {product.price}
                          </span>
                        </div>
                        <div className="txt-red mrg-top-5 text-secondary-color fw-400 fs-12">
                          {nonReturnExchangeMsg(product)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`mrg-top-10 md-flex xs-flex justify-end`}>
                    {product.id in orderInfo.request ? (
                      <div className="md-flex xs-flex flex-col">
                        <button
                          type="button"
                          onClick={() => {
                            const { request } = orderInfo;
                            delete request[product.id];
                            addProduct(request);
                            resetImages(product.id);
                          }}
                          className="ant-button ghost rounded-pill compact width-90 xs-width-80 mrg-btm-10"
                        >
                          Remove
                        </button>
                        <button
                          type="button"
                          onClick={() => onEdit(product.id)}
                          className="ant-button ghost rounded-pill compact width-90 xs-width-80"
                        >
                          Edit
                        </button>
                      </div>
                    ) : isDisabled ? null : (
                      <label className="radio-input small mrg-left-10">
                        <input
                          name="selection"
                          type="radio"
                          value={formData.selection}
                          checked={formData.selection === product.id}
                          required
                        />
                      </label>
                    )}
                  </div>
                </div>
              );
            })}
            {orderInfo?.returns?.length > 0 && productsToDisplay.length > 0 && (
              <p className="fs-18 fw-600 mrg-btm-10">
                Item(s) already in Return / {exchangeTexts?.[1]}
              </p>
            )}
            {orderInfo?.returns?.map(info => {
              const returnOrExchange =
                info?.list?.[0]?.return_mode === 'REFUND'
                  ? 'Return'
                  : exchangeTexts?.[1];
              urlParams.item_info_id = info.itemInfoIds;
              urlParams.return_id = info.returnIds;
              return (
                <React.Fragment key={info.returnIds}>
                  <div className="d-flex justify-between items-center mrg-btm-5 mrg-top-5">
                    <div className="fs-16 fw-600">
                      {udfKeys?.hideReferenceNumber ? (
                        <>{returnOrExchange} Request</>
                      ) : (
                        <>
                          {returnOrExchange} Request AWB :{' '}
                          {
                            info?.list?.[0]
                              ?.customer_returninfo_feed__reference_number
                          }
                        </>
                      )}
                    </div>
                    <div>
                      <Link
                        to={`/return-exchange/status?${new URLSearchParams(
                          urlParams
                        ).toString()}`}
                      >
                        <button
                          type="button"
                          className="ant-button ghost compact rounded-pill width-90 xs-width-80"
                        >
                          Track
                        </button>
                      </Link>
                    </div>
                  </div>
                  {info.list.map(product => (
                    <div
                      key={`${product.id}`}
                      className="bd-primary pd-15 space-y-3 rounded no-gutter justify-between bd-1 d-flex"
                    >
                      <div className="d-flex">
                        <img
                          src={product?.images || '/images/img-placeholder.svg'}
                          alt="product"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/img-placeholder.svg';
                          }}
                          width={100}
                          className="mrg-right-10 min-w-100 object-fit-cover img-rounded"
                          height={100}
                        />
                        <div
                          style={{ width: '100%' }}
                          className="md-flex xs-flex flex-col justify-between"
                        >
                          <div>
                            <div
                              className="text-primary-color fs-16 fw-600 mrg-btm-5"
                              style={{ wordBreak: 'break-all' }}
                            >
                              {product.description}
                            </div>
                            <div className="text-secondary-color fs-14 mrg-btm-5">
                              SKU {product.sku}
                            </div>
                          </div>
                          <div>
                            <div className="mrg-btm-5 fs-14">
                              <span className="text-secondary-color">
                                {info?.list?.[0]?.return_mode === 'REFUND'
                                  ? 'Returned'
                                  : exchangeTexts[2]}{' '}
                                Qty {product.return_quantity}
                              </span>
                              <span className="mrg-left-10 mrg-right-10 text-theme">
                                |
                              </span>
                              <span className="text-secondary-color fw-700">
                                {orderInfo.currency ??
                                  currencyMapper(config.country_code)}{' '}
                                {product.price}
                              </span>
                            </div>

                            <div className="txt-red text-secondary-color fw-400 fs-12">
                              {returnOrExchange} request already placed
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
      {udfKeys?.exchangeWindowCloseMessage &&
        eligibleforExchange.includes(false) && (
          <div className="d-flex bd-primary col-xs-24 space-y-3 rounded no-gutter bd-1 mrg-top-20 pd-btm-15 pd-top-15">
            <div className="pd-right-10">{infoIcon(user.subdomain)}</div>
            <div className="pd-right-10">
              {eligibleforExchange.includes(false) &&
                udfKeys?.exchangeWindowCloseMessage}
            </div>
          </div>
        )}
      <div
        className={`mrg-btm-20 mrg-top-20
         ${
           orderInfo.products.every(
             product =>
               !product.shipment_eligible_for_exchange &&
               !product.shipment_eligible_for_return
           )
             ? 'hide'
             : ''
         }
         `}
      >
        {!isDirty || requestProdList.length > 0 ? (
          <>
            <div
              className={`col-md-24 col-xs-24 pd-0 ${
                (!allow_future_returns && !allow_future_exchanges) ||
                requestProdList.length === 0
                  ? 'hide'
                  : ''
              }`}
            >
              <button
                type="button"
                onClick={requestProdList.length > 0 ? onNext : null}
                className="ant-button rounded-pill full-width"
              >
                {requestProdList.length > 0 ? 'Next' : 'Select an Item'}
              </button>
            </div>
            {udfKeys?.returnExchangeMessage &&
              eligibleforExchange.includes(true) && (
                <div className="d-flex bd-primary col-xs-24 space-y-3 rounded no-gutter bd-1 mrg-top-20 pd-btm-15 pd-top-15">
                  <div className="pd-right-10">{infoIcon(user.subdomain)}</div>
                  <div className="pd-right-10">
                    {eligibleforExchange.includes(true) &&
                      udfKeys?.returnExchangeMessage}
                  </div>
                </div>
              )}
          </>
        ) : (
          <div className="col-md-24 col-xs-24 pd-0">
            <div className="row">
              {isOnlyExchangeAllowed ? null : (
                <div className="col-md-12 col-xs-12">
                  <button
                    id={isExchangeDisabled ? '' : 'return-btn'}
                    onClick={handleSubmit(() => {
                      onSubmit('refund');
                      // reset();
                    })}
                    className={`ant-button ${
                      !isDirty ||
                      !selectedProduct.shipment_eligible_for_return ||
                      !allow_future_returns
                        ? 'disabled'
                        : ''
                    } d-flex items-center justify-center rounded-pill full-width`}
                  >
                    {returnSvg()}
                    <span className="mrg-left-5">{returnTexts?.[0]}</span>
                  </button>
                </div>
              )}
              <div
                className={`${
                  isOnlyExchangeAllowed
                    ? 'col-md-24 col-xs-24'
                    : 'col-md-12 col-xs-12'
                }`}
              >
                <button
                  onClick={
                    isExchangeDisabled
                      ? e => {
                          e.preventDefault();
                        }
                      : handleSubmit(() => {
                          onSubmit('exchange');
                          // reset();
                        })
                  }
                  className={`ant-button ${
                    isExchangeDisabled ? 'disabled' : ''
                  } rounded-pill full-width d-flex items-center justify-center`}
                >
                  {exchangeSvg()}
                  <span className="mrg-left-5">{exchangeTexts?.[0]}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

ProductSelection.propTypes = {
  onSubmit: PropTypes.any,
  orderInfo: PropTypes.any,
  tracking: PropTypes.any,
  addProduct: PropTypes.any,
  resetImages: PropTypes.any,
  exchangeConfig: PropTypes.any,
  user: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  orderInfo: state.orderInfo,
  tracking: state.tracking,
  exchangeConfig: state.exchangeConfig
});
const mapDispatchToProps = dispatch => ({
  addProduct: query => dispatch(addProduct(query)),
  resetImages: prodId => dispatch(resetImagesExchange(prodId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);
