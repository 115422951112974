/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addProduct } from '../../../service';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { currencyMapper } from '../../../utils/entity_mapping';
import { useExchangeContext } from '../wrapper';
import { selectVariantIn, stepActions, steps } from '../defaultValues';
import Instructions from '../components/Instructions';

export const noPriceSubdomains = ['puma', 'puma-test'];

export const useVariants = ({
  currentStep,
  variants,
  user,
  orderInfo,
  tracking,
  exchangeConfig
}) => {
  const [state, setState] = useState({ variant: '' });
  const { state: exchangeState } = useExchangeContext();
  const { config, udfKeys } = tracking.theme;

  const selectedProd = orderInfo.request[exchangeState.editProductId];

  const prevVariant = variants.list.find(
    variant => Number(variant.id) === Number(selectedProd?.thisProdVariant)
  );
  const catalogueConfig = exchangeConfig?.catalogueConfig;
  const variantList = variants.list
    .filter(
      item =>
        (item.inventory_quantity >= selectedProd.quantity ||
          (item.inventoryPolicy === 'CONTINUE' &&
            user.subdomain === 'letsdressup')) &&
        prevVariant?.sku !== item.sku
    )
    .map(item => ({
      label: (
        <div className="justify-between xs-flex d-flex">
          <span>{item.title}</span>{' '}
          {noPriceSubdomains.includes(user.subdomain) ||
          (catalogueConfig?.excess_payment_skip_in_variant_exchange &&
            catalogueConfig?.excess_refund_skip_in_variant_exchange) ||
          catalogueConfig?.excess_refund_skip_in_variant_exchange ? (
            ''
          ) : (
            <span className="fw-700 ml-auto">
              {orderInfo.currency ?? currencyMapper(config.country_code)}{' '}
              {item.price}
            </span>
          )}
        </div>
      ),
      title: item.title,
      value: item.id,
      sku: item.sku,
      price: item.price,
      inventory_quantity: item.inventory_quantity,
      discount_price: selectedProd.price_breakup?.discount_percent
        ? item.price -
          (item.price * selectedProd.price_breakup?.discount_percent) / 100
        : item.price
    }));

  const priceDiff =
    state.variant?.price -
    (selectedProd?.price - (selectedProd.price_breakup?.discount ?? 0));

  const priceDiffWithoutDiscount = state.variant?.price - selectedProd?.price;

  const submitHandler = e => {
    e?.preventDefault();
    if (!state.variant.value) {
      state.error =
        udfKeys.variantErrorText ?? 'Please select both size and color';
      setState({ ...state });

      return {
        error: udfKeys.variantErrorText ?? 'Please select both size and color'
      };
    }
    return {
      variant_id: state.variant?.value ?? '',
      selected_variant: state.variant.title,
      exchangeSku: state.variant.sku,
      previous_variant: prevVariant?.title ?? '',
      exchangePrice: state.variant.price,
      priceDiff,
      priceDiffWithoutDiscount,
      priceDiffWithDiscount:
        state.variant.discount_price -
        (selectedProd.price -
          (selectedProd.price_breakup?.discount_percent * selectedProd.price) /
            100)
    };
  };

  useEffect(() => {
    if (currentStep) {
      setState({
        variant:
          variantList.find(
            variant => variant.value === selectedProd.variant_id
          ) || ''
      });
    }
  }, [currentStep]);

  return {
    state,
    setState,
    variantList,
    submitHandler,
    prevVariant,
    priceDiff,
    priceDiffWithoutDiscount,
    priceDiffWithDiscount:
      state.variant.discount_price -
      (selectedProd.price -
        (selectedProd.price_breakup?.discount_percent * selectedProd.price) /
          100)
  };
};

let Variants = ({
  tracking,
  orderInfo,
  addProduct,
  variants,
  user,
  exchangeConfig
}) => {
  const {
    state: exchangeState,
    goForward,
    goBack,
    step
  } = useExchangeContext();
  const { request } = orderInfo;
  const { config, udfKeys } = tracking.theme;
  const selectedProd = request[exchangeState.editProductId];
  const isExchange = selectedProd?.type === 'exchange';
  const isMultiExchangeEnabled =
    tracking?.theme?.meta_data?.exchange?.allowMultiExchange;

  const {
    catalogueConfig: {
      service_active: catalogueEnabled,
      percentageDiscount,
      excess_payment_skip_in_variant_exchange,
      excess_refund_skip_in_variant_exchange
    },
    forward_order_discount
  } = exchangeConfig;

  const {
    variantList,
    setState,
    state,
    submitHandler,
    priceDiff,
    priceDiffWithDiscount,
    priceDiffWithoutDiscount
  } = useVariants({
    currentStep: step.current === steps.VARIANT_EXCHANGE,
    variants,
    user,
    orderInfo,
    tracking,
    exchangeConfig
  });
  let calculatedPriceDiff = priceDiff;

  if (forward_order_discount) {
    calculatedPriceDiff = priceDiffWithoutDiscount;
  } else if (percentageDiscount) {
    calculatedPriceDiff = priceDiffWithDiscount;
  } else {
    calculatedPriceDiff = priceDiff;
  }

  const onSubmit = e => {
    const variantInfo = submitHandler(e);

    if (variantInfo.error) {
      toast.error(variantInfo.error);
      return;
    }

    addProduct({
      ...orderInfo.request,
      [exchangeState.editProductId]: {
        ...selectedProd,
        ...variantInfo
      }
    });
    if (isMultiExchangeEnabled) {
      goForward(steps.ITEM_SELECTION);
    } else {
      goForward(steps.ADDRESS);
    }
  };

  const onChangeHandler = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (
      step.action === stepActions.REVERSE &&
      udfKeys.selectVariantIn === selectVariantIn.reasons
    ) {
      goBack(steps.REASON);
    }
  }, [step.current === steps.VARIANT_EXCHANGE]);

  const diffText = {
    1: `This item is ${currencyMapper(orderInfo.currency) ??
      orderInfo.currency} ${Math.abs(calculatedPriceDiff).toFixed(
      2
    )} more from original item`,
    0: `This item is same price as original item`,
    '-1': `This item is ${currencyMapper(orderInfo.currency) ??
      orderInfo.currency} ${Math.abs(calculatedPriceDiff).toFixed(
      2
    )} less from original item`
  };

  const priceDiffSign = Math.sign(calculatedPriceDiff);
  const skipRefund = excess_refund_skip_in_variant_exchange;
  const skipPayment = excess_payment_skip_in_variant_exchange;
  return (
    <form className="ht-100 d-flex flex-col" noValidate onSubmit={onSubmit}>
      <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={() => goBack(steps.REASON)}
          onKeyDown={() => goBack(steps.REASON)}
          tabIndex={0}
          role="button"
        />
        <span className="fs-20 fw-700 text-capitalize">
          Select {udfKeys.variantLabel ?? 'Variant'}
        </span>
      </div>
      <div className="d-flex flex-col ht-100 overflow-x-hidden overflow-auto bd-primary bd-1 xs-bd-0">
        <div className="bg-light pd-20 md-flex xs-flex">
          <img
            className="img-rounded object-fit-cover mrg-right-10"
            src={selectedProd?.images || '/images/img-placeholder.svg'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/img-placeholder.svg';
            }}
            width={71}
            height={71}
            alt="product"
          />
          <div className="text-secondary-color">
            <div className="text-primary-color fs-16">
              {selectedProd?.description}
            </div>
            <div className="fs-14">
              {orderInfo.currency ?? currencyMapper(config.country_code)}{' '}
              {selectedProd?.price}
            </div>
            <div className="fs-14">
              {isExchange ? 'Exchange' : 'Return'} Qty :{' '}
              {selectedProd?.quantity}
            </div>
          </div>
        </div>
        <div className="d-flex justify-between ht-100 flex-col">
          <div className="pd-20">
            <div className="text-secondary-color mrg-btm-5">
              Select {udfKeys.variantLabel ?? 'Variant'}:
            </div>
            <Select
              name={'variant'}
              value={state.variant}
              onChange={val => onChangeHandler('variant', val)}
              options={variantList}
              components={{
                SingleValue: props =>
                  noPriceSubdomains.includes(user.subdomain)
                    ? `${props.data.title}`
                    : `${props.data.title} ${
                        (skipRefund && skipPayment) || skipRefund
                          ? ''
                          : `- ${orderInfo.currency ??
                              currencyMapper(config.country_code)} ${
                              props.data.price
                            }`
                      }`
              }}
              className="custom-select text-primary-color"
              classNamePrefix="custom-select"
            />
          </div>
          {state.variant && catalogueEnabled && !skipRefund && !skipPayment ? (
            <div className="pd-20">
              <Instructions description={diffText[priceDiffSign]} />
            </div>
          ) : null}
        </div>
        {/* <div className="pd-20">
          <div>
            <span className="text-primary-color">Size : </span>
            <span className="text-secondary-color"> {state.size}</span>
          </div>
          <div className="md-flex xs-flex">
            {['XS', 'S', 'M', 'L', 'XL'].map(size => (
              <label
                key={size}
                htmlFor={`size-${size}`}
                className="size-radio-input space-x-3"
              >
                <input
                  disabled={size === 'XS'} // for representation of disabled state
                  required
                  id={`size-${size}`}
                  name="size"
                  type="radio"
                  value={size}
                  onChange={onChangeHandler('size', size)}
                  checked={size === state.size}
                />
                <div className="bd-1 cursor-pointer text-primary-color">
                  {size}
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="pd-20"> 
          <div>
            <span className="text-primary-color">Color : </span>
            <span className="text-secondary-color"> {state.color}</span>
          </div>
          <div className="md-flex xs-flex">
            {['#CD5252', '#CBCE26', '#22A1A9', '#2B138A', '#000000'].map(
              color => (
                <label key={color} className="color-radio-input space-x-3">
                  <input
                    required
                    name="color"
                    type="radio"
                    value={color}
                    onChange={onChangeHandler('color', color)}
                    checked={color === state.color}
                  />
                  <div
                    style={{ background: color, outlineColor: `${color}22` }}
                    className="cursor-pointer"
                  />
                </label>
              )
            )}
          </div>
              </div> */}
      </div>

      <div className="row mrg-top-15">
        <div className="col-md-24 pd-left-20 pd-right-20">
          <button type="submit" className="ant-button rounded-pill full-width">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

Variants.propTypes = {
  orderInfo: PropTypes.any,
  addProduct: PropTypes.any,
  user: PropTypes.any,
  variants: PropTypes.any,
  tracking: PropTypes.any,
  exchangeConfig: PropTypes.any
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo,
  user: state.user,
  variants: state.variants,
  tracking: state.tracking,
  exchangeConfig: state.exchangeConfig
});
const mapDispatchToProps = dispatch => ({
  addProduct: query => dispatch(addProduct(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(Variants);
