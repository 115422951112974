/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-class-assign */
import React, { Fragment } from 'react';
// import {  Field } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
// import { validateRequest } from './validate';
// import ImageRender from '../imageRender';
import { connect } from 'react-redux';
import { addImage, removeImage, imageUploadHandler } from '../../../service';
import { strings } from '../../../utils/localizedStrings';
import { allowedImageTypes } from '../../../utils/entity_mapping';
import toast from 'react-hot-toast';
// import { commonTextRegex } from '../../../utils/helper';

class RequestForm extends React.Component {
  state = { reason: null, comment: null, subreason: null };
  componentDidMount = () => {
    const { reason, comment, subreason } = this.props.initialValues;
    this.setState({ reason, subreason, comment: comment ?? '', errors: {} });
  };

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    };
  };

  fileRender = e => {
    const { lang, subdomain } = this.props.user;
    if (e.target.files.length > 0) {
      let imageFile = e.target.files[0];
      let { type } = imageFile;
      if (allowedImageTypes.indexOf(type) > -1) {
        imageUploadHandler(
          imageFile,
          subdomain,
          this.props.addImage,
          this.getBase64,
          this.props.orderInfo
        );
      } else {
        toast.error(strings[lang].invalidImageErr);
      }
    }
  };

  checkValidations = (
    { comment, reason } = {
      comment: this.state.comment,
      reason: this.state.reason,
      subreason: this.state.subreason
    }
  ) => {
    // const { lang } = this.props.user;
    let { errors } = this.state;

    // if (
    //   comment &&
    //   lang === 'en' &&
    //   comment !== String(comment).match(commonTextRegex[lang])?.[0]
    // ) {
    //   errors.comment = `Please enter valid english characters, ${
    //     comment?.[comment?.length - 1]
    //   } is not a valid character`;
    // } else
    if (
      (reason?.label === 'Others' &&
        ['roposo', 'trackmyorder'].includes(this.props.user.subdomain) &&
        !comment) ||
      (['shantanunikhil', 'shantanunikhil-test'].includes(
        this.props.user.subdomain
      ) &&
        !comment) 
    ) {
      errors.comment = 'Enter your reason in the comment box';
    } else {
      errors = {};
    }
    this.setState({ errors });
    return errors;
  };

  _handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.checkValidations({ comment: e.target.value });
  };

  _selectChange = (key, e) => {
    this.setState({ [key]: e });
    this.checkValidations({ [key]: e });
    if (key === 'reason') {
      this.props.reasonChange(e);
      this.setState({ subreason: null });
    }
  };

  onSubmitClick = () => {
    const { reason, subreason, comment } = this.state;
    if (Object.keys(this.checkValidations()).length > 0) {
      return;
    }

    this.props.onSubmit(reason, subreason, comment);
  };

  render() {
    const { user, orderInfo, page, tracking } = this.props;
    const { lang } = user;
    const { udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';
    const subreasons = this.props.reasons?.list?.find(
      reason => reason.label === this.state?.reason?.label
    )?.subReasons;
    let isNextDisabled = true;
    if (
      (this.state.reason && subreasons === undefined) ||
      (this.state.reason && subreasons?.length > 0 && this.state.subreason)
    ) {
      if (this.props.file.imageQuantity) {
        if (this.props.file.imageQuantity === 0) {
          isNextDisabled = false;
        } else if (
          this.props.file.list.length >= this.props.file.imageQuantity
        ) {
          isNextDisabled = false;
        } else if (
          this.props.file.imageQuantity > 0 &&
          this.props.file.list.length !== this.props.file.imageQuantity
        ) {
          isNextDisabled = true;
        }
      } else {
        isNextDisabled = false;
      }
      if (!orderInfo.isCaptureImage) {
        isNextDisabled = false;
      }
    }
    const isShantanuNikhil = ['shantanunikhil', 'shantanunikhil-test'].includes(
      user.subdomain
    );
    if(udfKeys?.commentMandatory && !this.state.comment){
      isNextDisabled=true;
    }
    return (
      <div className="row">
        <div className="col-md-24 col-xs-24">
          <section className="reason-box">
            <div className="row">
              <div className="col-md-24 col-xs-24">
                <div className="row">
                  <div className="col-md-24 col-xs-24 mrg-btm-10">
                    <div
                      className={`label mrg-btm-5 ${
                        !this.state.rezason ? 'red' : ''
                      } ${isShantanuNikhil && 'text-black'} ${
                        udfKeys.titleTextClassName
                      } `}
                    >
                      {strings[lang].reason}
                      <span className="mandatory">
                        <strong>*</strong>
                      </span>
                    </div>
                    <Select
                      value={this.state.reason ? this.state.reason : undefined}
                      name="reason"
                      onChange={e => this._selectChange('reason', e)}
                      options={this.props.reasons.list}
                      placeholder={
                        this.props.reasons.list.length <= 0
                          ? 'Fetching Reasons...'
                          : strings[lang]?.select ?? 'Select...'
                      }
                      className="custom-select"
                      classNamePrefix="custom-select"
                      simpleValue
                    />
                  </div>
                  <div className="col-md-24 col-xs-24 mrg-btm-10">
                    {this.state?.reason?.subReasons?.length > 0 && (
                      <>
                        <div
                          className={`label mrg-btm-5 ${
                            !this.state.subreason ? 'red' : ''
                          } ${udfKeys.titleTextClassName}`}
                        >
                          Subreason
                          <span className="mandatory">
                            <strong>*</strong>
                          </span>
                        </div>
                        <Select
                          value={
                            this.state.subreason ? this.state.subreason : null
                          }
                          name="subreason"
                          onChange={e => this._selectChange('subreason', e)}
                          options={subreasons}
                          placeholder={strings[lang]?.select ?? 'Select...'}
                          className="custom-select"
                          classNamePrefix="custom-select"
                          simpleValue
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-24 col-xs-24 mrg-btm-10">
                    <div className={`label ${udfKeys?.commentMandatory ? 'red' : ''} ${udfKeys.titleTextClassName}`}>
                      {strings[lang].anyOtherComment}{udfKeys?.commentMandatory ? '*':' (Optional)'}
                      {isShantanuNikhil && (
                        <span className="mandatory">
                          <strong>*</strong>
                        </span>
                      )}
                    </div>
                    <input
                      value={this.state.comment || ''}
                      name="comment"
                      onChange={this._handleChange}
                      type="textarea"
                      className={'ant-input'}
                      rows={3}
                      // pattern={commonTextRegex[lang]}
                      placeholder={
                        strings[lang]?.max200Chars ?? 'Max. 200 characters'
                      }
                      maxLength="200"
                    />
                    <div>
                      {this.state?.errors?.comment && (
                        <span className="error text-red">
                          {this.state.errors.comment}
                        </span>
                      )}

                      <span className="pull-right">
                        {strings[lang]?.charsLeft(
                          200 - this.state?.comment?.length ?? 0
                        ) ?? ' characters left'}
                      </span>
                    </div>
                  </div>
                  {this.props.isCaptureImage ? (
                    <Fragment>
                      <div className="col-md-24 col-xs-24 pd-top-10 pd-btm-10">
                        <div className="divider-horizontal mrg-top-0 mrg-btm-10"></div>
                        <h3
                          className={`mrg-btm-10 ${udfKeys.titleTextClassName}`}
                        >
                          {strings[lang].addImage}
                          {this.props.file.imageQuantity > 0 ? (
                            <span
                              className={`mandatory ${
                                this.props.file.imageQuantity > 0 ? '' : ''
                              }`}
                            >
                              <strong>*</strong>
                            </span>
                          ) : null}
                        </h3>
                        <p className="text-secondary-color fs-12 mrg-btm-10">
                          Only jpg, jpeg, png, heic are allowed
                        </p>
                      </div>
                      <div
                        className={`col-md-24 col-xs-24 ${
                          this.props.file.list.length <
                          this.props.file.imageQuantity
                            ? 'pd-btm-0'
                            : 'pd-btm-20'
                        }`}
                      >
                        {this.props.file.list.map((item, i) => (
                          <div
                            key={i}
                            className="uploaded-square-image-container"
                          >
                            <img src={item.base64} alt={item.name} />
                            <span
                              className="icon-close"
                              onClick={e => this.props.removeImage(i, e)}
                            />
                          </div>
                        ))}
                        {this.props.file.list.length <
                          this.props.file.imageQuantity ||
                        this.props.file.list.length < 3 ? (
                          <div
                            key={
                              this.props.file.list.length + Math.random() * 100
                            }
                            className="upload-square-button-container"
                          >
                            <input
                              type="file"
                              className="fileInput"
                              name="fileInput"
                              accept="image/*,image/heic,image/heif"
                              onChange={this.fileRender}
                            />
                            <span className="icon-plus" />
                          </div>
                        ) : null}
                      </div>
                      {this.props.file.imageQuantity > 0 ? (
                        <div
                          className={`col-md-24 col-xs-24 pd-btm-20 ${
                            this.props.file.list.length <
                            this.props.file.imageQuantity
                              ? 'show'
                              : 'hide'
                          }`}
                        >
                          <p className="message-required">
                            {strings[lang].pleaseUploadSupportingImage(
                              tracking?.theme?.imageUploadNote ?? '',
                              this.props.file.imageQuantity
                            )}
                          </p>
                        </div>
                      ) : null}
                    </Fragment>
                  ) : null}
                  <div className="col-md-24 col-xs-24 mrg-top-10 add-image-footer">
                    {!page.sku ? (
                      <button
                        className={`ant-button ghost ${
                          this.props.file.isUploading ? 'disable-element' : ''
                        }`}
                        onClick={this.props.backToList}
                        disabled={this.props.file.isUploading}
                      >
                        {strings[lang].back}
                      </button>
                    ) : null}
                    <button
                      type="submit"
                      className={`ant-button mrg-left-10 ${
                        isNextDisabled || this.props.file.isUploading
                          ? 'disable-element'
                          : ''
                      } ${page.sku ? 'full-width' : ''}`}
                      onClick={this.onSubmitClick}
                      disabled={isNextDisabled || this.props.file.isUploading}
                    >
                      {this.props.file.isUploading
                        ? strings[lang].processing
                        : strings[lang].next}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func,
  reasons: PropTypes.object,
  backToList: PropTypes.func,
  initialValues: PropTypes.object,
  isCaptureImage: PropTypes.bool,
  reasonChange: PropTypes.func,
  file: PropTypes.object,
  tracking: PropTypes.object,
  user: PropTypes.object,
  addImage: PropTypes.func,
  removeImage: PropTypes.func,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

// export default RequestForm;
const mapStateToProps = state => ({
  file: state.file,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page,
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  addImage: query => dispatch(addImage(query)),
  removeImage: idx => dispatch(removeImage(idx))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
