import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { infoIcon } from '../forms/date';
import useResize from '../utils/useResize';
import { currencyMapper } from '../../../utils/entity_mapping';
import { openUrl } from '../../../utils/helper';

const Status = ({ returnStatus, tracking, orderInfo, user, page }) => {
  const searchParams = new URLSearchParams(location.search);
  const uiState = useResize();

  return (
    <div
      className={`text-left main-container ${user.subdomain} ${
        page.appOnly ? 'webview' : ''
      } bg-brand-primary exchange`}
    >
      <div className="container-fluid text-primary-color">
        <div className="row-eq-height row return-container bg-brand-primary">
          <div
            className="col-sm-10 ht-100 d-flex flex-col col-sm-offset-6 col-md-8 col-md-offset-8 col-xs-24"
            style={{ minHeight: uiState.conHeight }}
          >
            <div className="d-flex flex-col justify-center mrg-top-20">
              {/* <div
                className="rounded-pill bg-theme d-flex justify-center items-center mrg-btm-10"
                style={{ width: 60, height: 60 }}
              >
                {exchangeIcon}
              </div> */}
              <p className="text-capitalize fw-600 fs-20">Exchange Created</p>
            </div>
            <div className="divider" />
            <div className="d-flex justify-between mrg-btm-10">
              <span className="fw-600">
                Exchange ID #{searchParams.get('return_id')}
              </span>
              <span>
                {moment(returnStatus.metadata.date).format('D MMM, YYYY')}
              </span>
            </div>
            <div className="bg-light-grey mrg-top-20 mrg-btm-20 rounded-xl d-flex rounded">
              <div className="img-status mrg-right-10 w-50">
                <img
                  alt="product"
                  src={returnStatus.items?.[0]?.image}
                  className="full-width"
                />
              </div>
              <div className="w-50">
                <p className="mrg-btm-10">
                  {returnStatus.items?.[0]?.description}
                </p>
                <p className="mrg-btm-10 text-secondary-color">
                  {page.newSize && (
                    <>
                      New Size: {page.newSize}
                      <span className="mrg-left-10 mrg-right-10">|</span>
                    </>
                  )}
                  Qty: 1
                </p>
                <p className="fw-600 fs-18">
                  {orderInfo.currency ??
                    currencyMapper(returnStatus.metadata.country)}{' '}
                  {returnStatus.items?.[0]?.price}
                </p>
              </div>
            </div>
            <p className="mrg-btm-20 text-secondary-color">
              In case the product is out of stock the refund against the
              original order will be credited into your credits account
            </p>
            <div className="bd-1 pd-10 mrg-btm-20">
              <p className="fw-600 mrg-btm-5">Address:</p>
              <p className=" fw-600 text-primary-color">
                {returnStatus.metadata.name}
              </p>
              <p className="text-secondary-color">
                {returnStatus.metadata.address}
              </p>
              <p className=" text-secondary-color">
                Pincode - {returnStatus.metadata.pincode}
              </p>
            </div>
            <div className="d-flex items-center text-theme">
              {/* {infoIcon()} */}
              <p className="mrg-left-10">Note :</p>
            </div>
            <ol className="list-mrg-0 text-secondary-color">
              <li className="mrg-btm-20">
                Repack the item with original packaging and accompanying return
                bag
              </li>
              <li className="mrg-btm-20">
                Please give your product to the courier guy
              </li>
              <li className="mrg-btm-20">
                Exchange product will be picked up first and then new product
                will be delivered
              </li>
            </ol>
            <div className="d-flex mt-auto">
              <button
                onClick={() =>
                  openUrl(tracking.theme.meta_data.my_order_url ?? '', 'blank')
                }
                className="ant-button mrg-right-10 ghost-black rounded-pill compact full-width mrg-btm-10"
              >
                My orders
              </button>

              <button
                onClick={() =>
                  openUrl(tracking.theme.meta_data.website ?? '', 'blank')
                }
                className="ant-button rounded-pill compact full-width xs-width-80 mrg-btm-10"
              >
                Continue shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Status.propTypes = {
  user: PropTypes.any,
  page: PropTypes.any,
  orderInfo: PropTypes.any,
  returnStatus: PropTypes.any,
  returnStatusHandler: PropTypes.any,
  tracking: PropTypes.any
};
export default Status;
