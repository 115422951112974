/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { login } from '../modules/login/actions';
import Select from 'react-select';
import Input from '../../../staticComponents/input';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { commonTextRegex, pincodePattern } from '../../../utils/helper';
// import { validateAddress } from './validate';
import { checkPincodeServiceability } from '../../../service';
import actionMessages from '../../../utils/messages';
import { strings } from '../../../utils/localizedStrings';
import { bfl, noServiceabilityCheck } from '../../../utils/entity_mapping';
import { getBooleanfromString } from '../../exchange/utils/helper';

class AddressForm extends Component {
  state = {
    name: '',
    email: '',
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    state: '',
    country: '',
    phone: '',
    postal_code: '', // added for BFL
    isSubmitted: false,
    isSelfShipped: false
  };

  _handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _selectChange = (option, name) => {
    this.setState({ [name]: option.value });
    if (name === 'city') {
      this.setState({ postal_code: '' });
    }
  };

  _handleSubmit = e => {
    e.preventDefault();
    const {
      name,
      email,
      address,
      landmark,
      pincode,
      city,
      state,
      country,
      phone,
      postal_code,
      isSubmitted
    } = this.state;
    this.props.onSubmit({
      name,
      email,
      address,
      landmark,
      pincode,
      city,
      state,
      country,
      phone,
      postal_code,
      isSubmitted
    });
  };

  _pincodeBlur = e => {
    e.preventDefault();
    const { pincode } = this.state;
    if (
      e.target.value.length === 6 &&
      pincodePattern.test(pincode) &&
      !noServiceabilityCheck.countries.includes(
        (this.state?.country ?? '').toLowerCase()
      )
    ) {
      this.props.checkPincodeServiceability(pincode).then(res => {
        const { result } = res;
        if (result.pincode_serviceable || this.props.subdomain === 'kushals') {
          const { city, state, country } = result.pincode_data;
          this.setState({ city, state, country, pincode });
        } else {
          toast.error(actionMessages.pincode.notServiceable);
        }
        this.setState({
          isSelfShipped: result.pincode_serviceable ? false : true
        });
      });
    }
  };

  componentDidMount = () => {
    const { address } = Object.assign(this.props);
    this.setState({ ...address, postal_code: address?.pincode ?? '' }); // postal_code for BFL
  };

  componentWillReceiveProps = nextProps => {
    const { pincode, city, state, country } = nextProps.address;
    const { address } = this.state;
    if (address.pincode !== pincode) {
      this.setState({ ...address, pincode, city, state, country });
    }
  };

  render() {
    const {
      back,
      user,
      bflCheck,
      locations,
      locationsFound,
      address,
      tracking
    } = this.props; // Using bflCheck as a boolean to hide / make readonly some address fields
    const { lang } = user;
    // let serviceableLocations = [];
    const { udfKeys } = tracking.theme;

    const isContactInfoEditable = udfKeys?.isContactInfoEditable
      ? getBooleanfromString(udfKeys?.isContactInfoEditable)
      : true;

    let areasObj = {};
    let cities = [];
    if (locationsFound && locations[this.state.country]) {
      const cityKeys = Object.keys(locations[this.state.country]);
      cities = cityKeys.map(city => ({
        label: city,
        value: city
      }));
      cityKeys.forEach(city => {
        const areas = locations[this.state.country][city].map(area => ({
          label: area,
          value: area
        }));
        areasObj[city] = areas;
      });
    }

    const isEmailPresent = address.email;

    return (
      <form className="row" onSubmit={this._handleSubmit} autoComplete="nope">
        <div className="col-md-24 col-xs-24">
          <section className="address-form pd-top-15">
            <div className="row">
              <div className="col-md-24 col-xs-24 mrg-btm-10">
                {this.state.isSubmitted
                  ? bflCheck
                    ? strings[lang]?.updatePickupAddress
                    : strings[lang]?.updateReversePickupAddress
                  : strings[lang]?.pickupAddress}
                <span className="icon-close pull-right" onClick={back} />
              </div>
              <div className="col-md-24 col-xs-24 mrg-btm-10">
                <div className="label">
                  {strings[lang].fullName}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                <Input
                  value={this.state.name}
                  name="name"
                  className="form-control"
                  onChange={this._handleChange}
                  required
                  pattern={commonTextRegex[lang]}
                  maxlength={200}
                />
              </div>
              <div className="col-md-24 col-xs-24 mrg-btm-10">
                <div className="label">
                  {strings[lang].emailId}

                  {isEmailPresent && (
                    <span className="mandatory">
                      <strong>*</strong>
                    </span>
                  )}
                </div>
                <Input
                  value={this.state.email}
                  name="email"
                  className="form-control"
                  onChange={this._handleChange}
                  maxlength={200}
                  readOnly={bflCheck || !isContactInfoEditable}
                  required={isEmailPresent}
                />
              </div>
              <div className="col-md-24 col-xs-24 mrg-btm-10">
                <div className="label">
                  {strings[lang].address}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                <Input
                  value={this.state.address}
                  name="address"
                  className="form-control"
                  onChange={this._handleChange}
                  pattern={commonTextRegex[lang]}
                  rows={3}
                  required
                  maxlength={200}
                  placeholder={
                    strings[lang]?.max200Chars ?? 'Max. 200 characters'
                  }
                />
              </div>
              <div className="col-md-24 col-xs-24 mrg-btm-10">
                <div className="label">{strings[lang].landmark}</div>
                <Input
                  value={this.state.landmark}
                  name="landmark"
                  className="form-control"
                  onChange={this._handleChange}
                  pattern={commonTextRegex[lang]}
                  maxlength={200}
                />
              </div>
              <div
                className={`col-md-12 col-xs-24 mrg-btm-10 ${bflCheck &&
                  !bfl.showPincode.includes(
                    (this.state?.country ?? '')?.toLowerCase()
                  ) &&
                  'hide'}`}
              >
                <div className="label">
                  {strings[lang].pincode}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                <Input
                  value={this.state.pincode}
                  name="pincode"
                  className="form-control"
                  onChange={this._handleChange}
                  onBlur={this._pincodeBlur}
                  required={!bflCheck}
                  maxlength={6}
                />
              </div>
              <div className="col-md-12 col-xs-24 mrg-btm-10">
                <div className="label">
                  {strings[lang].city}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                {bflCheck &&
                locationsFound &&
                locations?.[this.state.country] ? (
                  <Select
                    value={{ value: this.state.city, label: this.state.city }}
                    name="city"
                    onChange={option => this._selectChange(option, 'city')}
                    options={
                      locationsFound && locations?.[this.state.country]
                        ? cities
                        : bfl?.cities?.[this.state.country] ?? []
                    }
                    placeholder="Select..."
                    className="custom-select bfl text-primary-color"
                    classNamePrefix="custom-select"
                    simpleValue
                    required
                  />
                ) : (
                  <Input
                    value={this.state.city}
                    name="city"
                    className="form-control"
                    onChange={this._handleChange}
                    required
                    maxlength={40}
                    readOnly={bflCheck}
                  />
                )}
                {/* {bflCheck ? (
                  <Select
                    value={{ value: this.state.city, label: this.state.city }}
                    name="city"
                    onChange={this._selectChange}
                    options={bfl?.cities?.AE ?? []}
                    placeholder="Select..."
                    className="custom-select bfl text-primary-color"
                    classNamePrefix="custom-select"
                    simpleValue
                    required
                  />
                ) : (
                  <Input
                    value={this.state.city}
                    name="city"
                    className="form-control"
                    onChange={this._handleChange}
                    required
                    maxlength={40}
                    readOnly={bflCheck}
                  />
                )} */}
              </div>
              {bflCheck && locationsFound && locations?.[this.state.country] && (
                <div className="col-md-12 col-xs-24 mrg-btm-10">
                  <div className="label">
                    {strings[lang].area}
                    <span className="mandatory">
                      <strong>*</strong>
                    </span>
                  </div>
                  <Select
                    value={{
                      value: this.state.postal_code,
                      label: this.state.postal_code
                    }}
                    name="postal_code"
                    onChange={option =>
                      this._selectChange(option, 'postal_code')
                    }
                    options={
                      locationsFound &&
                      locations[this.state.country] &&
                      this.state.city
                        ? areasObj[this.state.city]
                        : []
                    }
                    placeholder="Select..."
                    className="custom-select bfl text-primary-color"
                    classNamePrefix="custom-select"
                    simpleValue
                    required
                  />
                </div>
              )}
              <div
                className={`col-md-12 col-xs-24 mrg-btm-10 ${bflCheck &&
                  'hide'}`}
              >
                <div className="label">
                  {strings[lang].state}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                <Input
                  value={this.state.state}
                  name="state"
                  className="form-control"
                  onChange={this._handleChange}
                  required={!bflCheck}
                  maxlength={40}
                  readOnly={bflCheck}
                />
              </div>
              <div
                className={`${
                  bflCheck ? 'col-md-6' : 'col-md-12'
                } col-xs-24 mrg-btm-10`}
              >
                <div className="label">
                  {strings[lang].country}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </div>
                <Input
                  value={this.state.country}
                  name="country"
                  className="form-control"
                  onChange={this._handleChange}
                  required
                  maxlength={40}
                  readOnly={bflCheck}
                />
              </div>
              {bflCheck && (
                <div className="col-md-6 col-xs-24 mrg-btm-10">
                  <div className="label">{strings[lang].phoneCode}</div>
                  <Input
                    value={this.state.phoneCode}
                    name="phoneCode"
                    className="form-control"
                    // onChange={this._handleChange}
                    // required
                    readOnly
                  />
                </div>
              )}
              <div
                className={`${
                  bflCheck ? 'col-md-12 col-xs-24' : 'col-md-24 col-xs-24'
                } mrg-btm-10`}
              >
                <div className="label">
                  {strings[lang].phoneNumber}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                  {bflCheck && (
                    <i>
                      (
                      {lang !== 'ar'
                        ? `${strings[lang]?.format}: ` ?? 'format:'
                        : null}
                      {bfl.phoneCodes[this.state.country]?.format ?? 'NA'}
                      {lang === 'ar'
                        ? `: ${strings[lang]?.format}` ?? 'format:'
                        : null}
                      )
                    </i>
                  )}
                </div>
                <Input
                  value={this.state.phone}
                  name="phone"
                  className="form-control"
                  onChange={this._handleChange}
                  required
                  maxlength={20}
                  readOnly={!isContactInfoEditable}
                />
              </div>
              <div className="col-md-24 col-xs-24">
                {/* <Button
                  type="primary"
                  className="mrg-right-10"
                  htmlType="submit"
                >
                  Next
                </Button> */}
                <button className="ant-button mrg-right-10" type="submit">
                  {strings[lang].next}
                </button>
                {/* <Button type="primary" ghost onClick={back}>
                  Back
                </Button> */}
                <button className="ant-button ghost" onClick={back}>
                  {strings[lang].back}
                </button>
              </div>
            </div>
          </section>
        </div>
      </form>
    );
  }
}

AddressForm.contextTypes = {
  router: PropTypes.object.isRequired
};

AddressForm.propTypes = {
  address: PropTypes.object,
  history: PropTypes.object,
  back: PropTypes.func,
  pincodeChange: PropTypes.object,
  checkPincodeServiceability: PropTypes.object,
  onSubmit: PropTypes.func,
  user: PropTypes.object,
  bflCheck: PropTypes.bool,
  locationsFound: PropTypes.bool,
  locations: PropTypes.object,
  subdomain: PropTypes.string,
  tracking: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  checkPincodeServiceability: query =>
    dispatch(checkPincodeServiceability(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
