import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { infoIcon } from '../utils/svgs';
import useResize from '../utils/useResize';
import { openUrl } from '../../../utils/helper';

const exchangeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 96 960 960"
    width="48"
    fill="#fff"
  >
    <path d="M271 936 80 745l192-192 42 42-120 120h649v60H194l119 119-42 42Zm418-337-42-42 119-119H117v-60h649L646 258l42-42 192 192-191 191Z" />
  </svg>
);

const Status = ({ returnStatus, user, page }) => {
  const searchParams = new URLSearchParams(location.search);
  const uiState = useResize();

  return (
    <div
      className={`text-left main-container ${user.subdomain} ${
        page.appOnly ? 'webview' : ''
      } bg-brand-primary`}
    >
      <div className="container-fluid">
        <div className="row-eq-height row return-container bg-brand-primary">
          <div
            className="col-md-8 ht-100 d-flex flex-col col-md-offset-8 col-xs-24"
            style={{ minHeight: uiState.conHeight }}
          >
            <div className="d-flex flex-col text-center justify-center items-center mrg-top-20">
              <div
                className="rounded-pill bg-theme d-flex justify-center items-center mrg-btm-10"
                style={{ width: 60, height: 60 }}
              >
                {exchangeIcon}
              </div>
              <p className="text-uppercase fw-600">Exchange Created</p>
            </div>
            <div className="divider mrg-btm-5" />
            <div className="d-flex justify-between mrg-btm-10">
              <span className="fw-600">
                Exchange ID #{searchParams.get('return_id')}
              </span>
              <span>
                {moment(returnStatus.metadata.date).format('D MMM, YYYY')}
              </span>
            </div>
            <div className="bg-light-grey mrg-top-20 mrg-btm-20 rounded-xl d-flex pd-15 rounded">
              <img
                alt="product"
                src={returnStatus.items?.[0]?.image}
                className="mrg-right-10 img-rounded"
                style={{ width: 70 }}
              />
              <div>
                <p className="mrg-btm-10">
                  {returnStatus.items?.[0]?.description}
                </p>
                {page.newSize && <p>New Size: {page.newSize}</p>}
              </div>
            </div>
            <p className="mrg-top-20 fw-600 text-primary-color">
              {returnStatus.metadata.name}
            </p>
            <p className="text-secondary-color">
              {returnStatus.metadata.address}
            </p>
            <p className="mrg-btm-30 text-secondary-color">
              Pincode - {returnStatus.metadata.pincode}
            </p>
            <div className="d-flex items-center text-theme">
              {infoIcon()}
              <p className="mrg-left-10">Exchange Request Submitted</p>
            </div>
            <ol className="list-mrg-0">
              <li className="mrg-btm-20">
                Repack the item with original packaging and accompanying return
                bag
              </li>
              <li className="mrg-btm-20">
                Please give your product to the courier guy
              </li>
              <li className="mrg-btm-20">
                Exchange product will be picked up first and then new product
                will be delivered
              </li>
            </ol>
            <div className="d-flex mt-auto">
              <button
                onClick={() =>
                  openUrl(
                    'https://www.pantaloons.com/myaccount/orders?utm_clickpost=my%20orders%7Cexchange%20creation' ??
                      '',
                    'blank'
                  )
                }
                className="ant-button mrg-right-10 ghost-black rounded-pill compact full-width mrg-btm-10"
              >
                My orders
              </button>
              <button
                onClick={() =>
                  openUrl(
                    'https://www.pantaloons.com/?utm_clickpost=continue%20shopping%7Cexchange%20creation' ??
                      '',
                    'blank'
                  )
                }
                className="ant-button ghost-black rounded-pill compact full-width xs-width-80 mrg-btm-10"
              >
                Continue shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Status.propTypes = {
  user: PropTypes.any,
  page: PropTypes.any,
  orderInfo: PropTypes.any,
  returnStatus: PropTypes.any,
  returnStatusHandler: PropTypes.any,
  tracking: PropTypes.any
};
export default Status;
