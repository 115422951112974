/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { currencyMapper } from '../../utils/entity_mapping';
import { strings } from '../../utils/localizedStrings';

const MyLoader = props => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="15" rx="3" ry="3" width="250" height="12" />
    <rect x="150" y="40" rx="3" ry="3" width="250" height="12" />
    <rect x="10" y="10" width="100" height="120" />
  </ContentLoader>
);

class PlacedOrder extends Component {
  state = { source: '', token: '' };
  componentDidMount() {
    const searchParams = new URLSearchParams(
      this.props.history.location.search
    );
    this.setState({
      source: searchParams.get('source'),
      token: searchParams.get('token')
    });
  }
  pushRoute = (e, route) => {
    this.props.history.push(route);
  };
  render() {
    const { orderInfo, user, tracking } = this.props;
    const { routePrefix } = user;
    const { action, step, returns } = orderInfo;
    const { config, meta_data } = tracking.theme;
    const { source, token } = this.state;
    const { lang } = user;
    const { udfKeys } = tracking.theme;
    return !orderInfo.isFetching && returns.length > 0 ? (
      step.current === 1 && !action.isReturnClk ? (
        <Fragment>
          <h3>{strings[lang].returnRequestReceived}</h3>
          <div className="row return-list">
            {returns.map((item, i) => (
              <div key={i} className="col-md-24 col-xs-24">
                <section className="content-holder">
                  <div className="row">
                    <div className="col-md-24 col-xs-24 mrg-btm-15">
                      <span className="order-number pull-left">
                        {strings[lang].requestPlacedDate}:{' '}
                        {moment(item.date).format('DD-MM-YYYY') || ' '}
                      </span>
                      {/* <span className="order-number pull-left mrg-left-5 xs-mrg-left-0 xs-mrg-top-10">
                        Return ID: {item.returnIds}
                      </span> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-24 col-xs-24 xs-pd-left-15 pd-right-0 xs-pd-right-15">
                      <div className="row item-productInfo row-flex">
                        {orderInfo.isFetching ? (
                          <div className="col-md-24 col-xs-24 bd-top-width-1">
                            <section className="content preloader">
                              <MyLoader />
                            </section>
                          </div>
                        ) : (
                          <Fragment>
                            <div className="col-md-22 col-xs-21 pd-right-0">
                              {item.list.map((product, i) => (
                                <section
                                  key={i}
                                  className={`content pd-right-0 ${
                                    orderInfo.action.activeIndex === i
                                      ? 'bd-top-width-1'
                                      : ''
                                  }`}
                                >
                                  <div className="row">
                                    <div className="col-md-24 col-xs-24 pd-right-0">
                                      <div className="item-thumbnail">
                                        <a
                                          href={product.product_url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={
                                              product.images ||
                                              (meta_data.image_placeholder ??
                                                (user.subdomain ===
                                                  'truemeds' ||
                                                user.subdomain ===
                                                  'truemeds-test'
                                                  ? '/images/medicine.svg'
                                                  : '/images/apparel.svg'))
                                            }
                                            alt="product"
                                          />
                                        </a>
                                      </div>
                                      <div className="item-info">
                                        <div className="item-productName">
                                          {product.description}
                                        </div>
                                        <div className="size-block">
                                          <span className="label">SKU </span>
                                          <span className="label">
                                            {product.sku}
                                          </span>
                                        </div>
                                        {user.subdomain === 'sharepal-test' ||
                                        user.subdomain === 'sharepal' ? null : (
                                          <>
                                            <div className="size-block">
                                              <span className="label">
                                                {strings[lang]?.qty ?? 'Qty'}{' '}
                                              </span>{' '}
                                              <span className="label">
                                                {product.return_quantity}
                                              </span>
                                            </div>
                                            {udfKeys?.hideItemPrice ? null : (
                                              <>
                                                <div className="size-block">
                                                  {orderInfo.currency ??
                                                    currencyMapper(
                                                      config.country_code
                                                    )}{' '}
                                                  {product.price}
                                                </div>
                                                <p>
                                                  {
                                                    strings[lang]
                                                      .allPriceShownPerItem
                                                  }
                                                </p>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className={`action ${
                                          action.isReturnClk ? 'show' : 'hide'
                                        } ${
                                          action.activeIndex === i
                                            ? 'editing'
                                            : ''
                                        }`}
                                      />
                                    </div>
                                  </div>
                                </section>
                              ))}
                            </div>
                            <div
                              className="col-md-1 col-xs-2 xs-pd-left-0 order-detail"
                              onClick={e =>
                                this.pushRoute(
                                  e,
                                  `${
                                    routePrefix ? `/${routePrefix}` : ''
                                  }/returns/status?security_key=${
                                    orderInfo.shipment_uuid
                                  }&waybill=${orderInfo.awb}&item_info_id=${
                                    item.itemInfoIds
                                  }&return_id=${item.returnIds}${
                                    source ? `&source=${source}` : ''
                                  }${token ? `&token=${token}` : ''}`
                                )
                              }
                            >
                              <a>
                                <div className="pos-center">
                                  <span className="icon-navigate_next fs-32" />
                                </div>
                              </a>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))}
          </div>
        </Fragment>
      ) : null
    ) : null;
  }
}

PlacedOrder.propTypes = {
  orderInfo: PropTypes.object,
  user: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object
};

PlacedOrder.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo,
  user: state.user,
  tracking: state.tracking
});

export default withRouter(connect(mapStateToProps, null)(PlacedOrder));
