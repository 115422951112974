/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { datetimeFormatter } from '../../utils/helper';
import { returnStatusHandler } from '../../service';
import Wrapper, { useExchangeContext } from './wrapper';
import { strings } from '../../utils/localizedStrings';
import { infoIcon } from './forms/reasons';
import { headsupfortails } from './forms/validate';
import { CollectiveStatusPage, PantaloonsStatusPage } from './statusPage';
import { MyLoader } from './forms/productSelection';
import NotFound from './NotFoundExchange';

const expandLess = subdomain => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 8L6 3.42L10.59 8L12 6.59L6 0.590001L-1.23266e-07 6.59L1.41 8Z"
      fill={`var(--base-${subdomain === '82e' ? 'primary' : 'border'}-color)`}
    />
  </svg>
);

const expandMore = subdomain => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.59 -1.85161e-06L6 4.58L1.41 -2.46532e-07L2.46532e-07 1.41L6 7.41L12 1.41L10.59 -1.85161e-06Z"
      fill={`var(--base-${subdomain === '82e' ? 'primary' : 'border'}-color)`}
    />
  </svg>
);

const Card = ({
  user,
  icon,
  heading,
  className,
  product,
  returnStatus: { timeline, rejectionReason }
}) => {
  const [state, setState] = useState({
    isOpen: false,
    showMore: false
  });
  const toggleState = key => () =>
    setState(prev => ({
      ...prev,
      [key]: !state[key]
    }));
  const { subdomain, lang } = user;

  return (
    <div className={'bg-brand-secondary rounded bg-light ' + className}>
      <div className="pd-20">
        <div className="text-primary-color fw-600 mrg-btm-15">
          {icon} {heading}
        </div>
        <div>
          {product?.list?.map(({ src, name, quantity }, idx) => (
            <div key={idx} className="md-flex xs-flex">
              <img
                src={src || '/images/img-placeholder.svg'}
                alt="product"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/img-placeholder.svg';
                }}
                className="mrg-right-5 img-rounded"
                width={52}
                height={52}
              />
              <div className="text-secondary-color">
                <div>{name}</div>
                {/* <div>Size : M</div> */}
                <div>Quantity : {quantity}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr className="mrg-top-0 mrg-btm-0 bd-secondary" />
      <div className="pd-20">
        <div className="md-flex xs-flex justify-between">
          <span className="text-secondary-color">
            Current Status :{' '}
            <span className="fw-600 text-primary-color">
              {timeline?.statuses?.[timeline?.reached]}
            </span>
          </span>
          <span
            onClick={toggleState('isOpen')}
            style={{ width: 26, height: 26 }}
            className="border-radius-100 cursor-pointer md-flex xs-flex justify-center items-center bd-primary bd-1 bg-white bg-brand-primary"
          >
            {state.isOpen ? expandLess(subdomain) : expandMore(subdomain)}
          </span>
        </div>
        {state.isOpen && (
          <div className="bg-white compact-theme mrg-top-10">
            <div className="bg-brand-primary timeline-card shadow-0 row">
              <div className="col-md-24 col-xs-24 text-left">
                <ul className="vertical-timeline pd-left-0 xs-pd-left-0">
                  {timeline.statuses.map((item, i) => (
                    <li
                      key={i}
                      className={`${
                        i < timeline.reached ? 'active' : ''
                      } items-center md-flex xs-flex exchange`}
                    >
                      {i <= timeline.reached ? (
                        <span className="onTheWay exchange"></span>
                      ) : (
                        <span className=" circle bg-brand-primary"></span>
                      )}
                      <p
                        className={`fs-14 ${
                          i <= timeline.reached ? 'fw-600' : ''
                        }`}
                      >
                        {item}
                        {strings[lang].requestCancelled === item &&
                          rejectionReason && (
                            <p className=" fs-12 fw-400 li-ht-16">
                              Reason:{' '}
                              {rejectionReason?.length > 50 && state.showMore
                                ? rejectionReason
                                : rejectionReason?.slice(0, 50)}
                              {rejectionReason?.length > 50 && (
                                <span
                                  className="fw-600 btn-underline"
                                  onClick={toggleState('showMore')}
                                >
                                  ...see {state.showMore ? 'less' : 'more'}
                                </span>
                              )}
                            </p>
                          )}
                      </p>
                      {/* {item === 'Request Placed' &&
                      tracking.config.orderDate ? (
                        <p className="label">
                          Order Date:{' '}
                          {datetimeFormatter(
                            tracking.config.orderDate,
                            false,
                            false,
                            'DD MMM YYYY'
                          )}
                        </p>
                      ) : null} */}
                      {item.location ? (
                        <p className="label">Location: {item.location}</p>
                      ) : null}
                      {item.timestamp ? (
                        <p className="label">
                          {datetimeFormatter(
                            item.timestamp,
                            false,
                            false,
                            'DD MMM YYYY'
                          )}
                        </p>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Status = props => {
  const [state, setState] = useState({
    showMore: false,
    showReturnTimeline: true,
    showWhatsnext: true
  });
  const {
    labels: { exchangeTexts }
  } = useExchangeContext();
  const { user, page, returnStatusHandler, returnStatus, tracking } = props;
  const { items, awb, timeline, rejectionReason } = returnStatus;
  const { search } = location;
  const searchParams = new URLSearchParams(search);

  const returnOrExchange =
    returnStatus.exchangeItem?.length > 0 ? exchangeTexts?.[1] : 'Return';

  const toggleState = key => () => {
    state[key] = !state[key];
    setState({ ...state });
  };

  useEffect(() => {
    const query = {
      key: searchParams.get('security_key'),
      waybill: searchParams.get('waybill'),
      itemInfoIds: searchParams.get('item_info_id'),
      returnIds: searchParams.get('return_id'),
      domain: user.subdomain
    };
    returnStatusHandler(query);
    setTimeout(() => {
      if (searchParams.get('redirectURI')) {
        window.location.href = searchParams.get('redirectURI');
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      returnStatus?.refundAutomationEnabled &&
      returnStatus?.refundTimeline?.timeline?.length > 0
    ) {
      state.showReturnTimeline = false;
      setState({ ...state });
    }
  }, [returnStatus.refundTimeline?.timeline?.length > 0]);

  // eslint-disable-next-line react/prop-types
  const Container = ({ children }) => (
    <div
      className={`text-left main-container ${user.subdomain} ${
        page.appOnly ? 'webview' : ''
      } bg-brand-primary exchange`}
    >
      <div className="container-fluid">
        <div className="row-eq-height row return-container bg-brand-primary">
          <div className="col-md-8 ht-100 col-md-offset-8 col-xs-24">
            {children}
          </div>
        </div>
      </div>
    </div>
  );

  if (returnStatus.error) {
    return (
      <Container>
        <NotFound noMessage />
      </Container>
    );
  }

  if (returnStatus.isFetching || returnStatus.items.length === 0) {
    return (
      <Container>
        <MyLoader />
      </Container>
    );
  }

  switch (user.subdomain) {
    case 'pantaloons':
    case 'abfrl-test2':
      // case 'clickpost-returns-4':
      return <PantaloonsStatusPage {...props} />;
    case 'thecollective':
    case 'abfrl-test':
      return <CollectiveStatusPage {...props} />;
  }

  const whatsNext = tracking.theme.meta_data?.whatsNext;
  const whatsNextLabel = whatsNext?.exchangeList?.map(item => item.label);
  return (
    <div
      className={`text-left main-container ${user.subdomain} ${
        page.appOnly ? 'webview' : ''
      } bg-brand-primary exchange`}
    >
      <div className="container-fluid">
        <div className="row-eq-height row return-container bg-brand-primary">
          <div className="col-md-8 ht-100 col-md-offset-8 col-xs-24">
            <div className="bd-primary bd-1 xs-bd-0 pd-20 xs-pd-0 mrg-btm-20 mrg-top-30">
              <div className="mrg-top-10 text-center mrg-btm-20">
                <img src="/images/Animation.svg" width={150} alt="success" />
                <div className="fs-20 fw-600 text-primary-color mrg-top-10 mrg-btm-10">
                  {returnOrExchange} Requested
                </div>
                {awb && user.subdomain !== 'swybrands-test' && (
                  <div className="fs-18 text-secondary-color">AWB: {awb}</div>
                )}
              </div>
              <hr className="w-30 border-status" />
              {state.showWhatsnext &&
              whatsNext?.active &&
              whatsNextLabel?.length > 0 &&
              whatsNext?.exchangeTitle ? (
                <div className="whatsnext">
                  <div className="d-flex justify-between">
                    <p className="fs-16 fw-600">{whatsNext?.exchangeTitle}</p>
                    <p>
                      <span
                        className="icon-close cursor-pointer"
                        onClick={() =>
                          setState({ ...state, showWhatsnext: false })
                        }
                      />
                    </p>
                  </div>
                  <ul>
                    {whatsNext?.exchangeList.map(item => (
                      <li
                        key={item.id}
                        className={`${
                          item.label?.length <= 0 ? 'hide' : ''
                        } fw-400 pd-top-10`}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <p className="fw-600 text-primary-color">
                {returnOrExchange} Item(s)
              </p>
              <div className="bg-brand-secondary bg-light">
                <div className="pd-20">
                  {items
                    ?.map(item => ({
                      name: item?.description,
                      quantity: item?.quantity,
                      src: item?.image
                    }))
                    ?.map(({ src, name, quantity }, idx) => (
                      <div key={idx} className="md-flex xs-flex mrg-btm-10">
                        <img
                          src={src || '/images/img-placeholder.svg'}
                          alt="product"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/img-placeholder.svg';
                          }}
                          className="mrg-right-5 img-rounded"
                          width={52}
                          height={52}
                        />
                        <div className="text-secondary-color">
                          <div>{name}</div>
                          {/* <div>Size : M</div> */}
                          <div>Quantity : {quantity}</div>
                        </div>
                      </div>
                    ))}
                </div>
                <hr className="mrg-top-0 mrg-btm-0 bd-secondary" />
                <p className="pd-20 text-secondary-color">
                  Current Status :
                  {returnStatus.refundAutomationEnabled &&
                  returnStatus.refundTimeline.timeline.length > 0 ? (
                    <span
                      className="fw-600 mrg-left-5"
                      style={{ color: returnStatus?.refundTimeline?.color }}
                    >
                      {
                        returnStatus.refundTimeline.timeline[
                          returnStatus.refundTimeline.reached
                        ].status
                      }
                    </span>
                  ) : (
                    <span className="text-primary-color fw-600 mrg-left-5">
                      {
                        returnStatus?.timeline?.statuses?.[
                          returnStatus?.timeline?.reached
                        ]
                      }
                    </span>
                  )}
                </p>
              </div>
              <div
                className="text-primary-color fw-600 mrg-top-20 cursor-pointer"
                onClick={toggleState('showReturnTimeline')}
              >
                Return Progress
                <span className="mrg-left-10">
                  {state.showReturnTimeline
                    ? expandLess(user.subdomain)
                    : expandMore(user.subdomain)}
                </span>
              </div>
              {state.showReturnTimeline ? (
                <div className="bg-brand-secondary bg-light pd-20 mrg-top-10">
                  <div className="bg-white compact-theme">
                    <div className="bg-brand-primary timeline-card shadow-0 row">
                      <div className="col-md-24 col-xs-24 text-left">
                        <ul className="vertical-timeline pd-left-0 xs-pd-left-0">
                          {timeline.statuses.map((item, i) => (
                            <li
                              key={i}
                              className={`${
                                i < timeline.reached ? 'active' : ''
                              } items-center md-flex xs-flex exchange`}
                            >
                              {i <= timeline.reached ? (
                                <span className="onTheWay exchange"></span>
                              ) : (
                                <span className=" circle bg-brand-primary"></span>
                              )}
                              <p
                                className={`fs-14 ${
                                  i <= timeline.reached ? 'fw-600' : ''
                                }`}
                              >
                                {item}
                                {strings[user.lang].requestCancelled === item &&
                                  rejectionReason && (
                                    <p className=" fs-12 fw-400 li-ht-16">
                                      Reason:{' '}
                                      {rejectionReason?.length > 50 &&
                                      state.showMore
                                        ? rejectionReason
                                        : rejectionReason?.slice(0, 50)}
                                      {rejectionReason?.length > 50 && (
                                        <span
                                          className="fw-600 btn-underline"
                                          onClick={toggleState('showMore')}
                                        >
                                          ...see{' '}
                                          {state.showMore ? 'less' : 'more'}
                                        </span>
                                      )}
                                    </p>
                                  )}
                              </p>
                              {/* {item === 'Request Placed' &&
                      tracking.config.orderDate ? (
                        <p className="label">
                          Order Date:{' '}
                          {datetimeFormatter(
                            tracking.config.orderDate,
                            false,
                            false,
                            'DD MMM YYYY'
                          )}
                        </p>
                      ) : null} */}
                              {item.location ? (
                                <p className="label">
                                  Location: {item.location}
                                </p>
                              ) : null}
                              {item.timestamp ? (
                                <p className="label">
                                  {datetimeFormatter(
                                    item.timestamp,
                                    false,
                                    false,
                                    'DD MMM YYYY'
                                  )}
                                </p>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {returnStatus.refundAutomationEnabled &&
              returnStatus.refundTimeline.timeline.length > 0 ? (
                <>
                  <p className="text-primary-color fw-600 mrg-top-20">
                    Refund Progress
                  </p>
                  <div className="bg-brand-secondary bg-light pd-20 mrg-top-10">
                    <div className="bg-white compact-theme">
                      <div className="bg-brand-primary timeline-card shadow-0 row">
                        <div className="col-md-24 col-xs-24 text-left">
                          <ul className="vertical-timeline pd-left-0 xs-pd-left-0">
                            {returnStatus.refundTimeline.timeline.map(
                              (item, i) => (
                                <li
                                  key={i}
                                  className={`${
                                    i < returnStatus.refundTimeline.reached
                                      ? 'active'
                                      : ''
                                  } items-center md-flex xs-flex exchange${
                                    returnStatus.refundTimeline?.timeline?.find(
                                      timelineItem =>
                                        timelineItem.status === 'Refund Failed'
                                    )
                                      ? ' failed-timeline'
                                      : ''
                                  }`}
                                >
                                  {i <= returnStatus.refundTimeline.reached ? (
                                    <span
                                      className={`onTheWay exchange${
                                        item.status === 'Refund Failed'
                                          ? ' failed'
                                          : ''
                                      }`}
                                    ></span>
                                  ) : (
                                    <span className=" circle bg-brand-primary"></span>
                                  )}
                                  <div
                                    className={`fs-14 ${
                                      i <= returnStatus.refundTimeline.reached
                                        ? 'fw-600'
                                        : ''
                                    }`}
                                  >
                                    <p>{item.status}</p>
                                    {item.timestamp ? (
                                      <p className="label fw-400">
                                        {datetimeFormatter(
                                          item.timestamp,
                                          false,
                                          false,
                                          'DD MMM YYYY'
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {/* <Card
                heading={`${returnOrExchange} Item(s)`}
                icon={
                  <img
                    src={'/images/reverse.svg'}
                    alt="reverse"
                    className=" mrg-right-5"
                  />
                }
                className="mrg-btm-10"
                product={{
                  awb,
                  list: items.map(item => ({
                    name: item?.description,
                    quantity: item?.quantity,
                    src: item?.image
                  }))
                }}
                returnStatus={returnStatus}
                user={user}
              /> */}
              {/* <Card
                heading={'Forward Item'}
                icon={
                  <img
                    src={'/images/forward.svg'}
                    alt="forward"
                    className="mrg-right-5"
                  />
                }
                product={{
                  awb: '39475',
                  name: exchangeItem?.itemName,
                  quantity: exchangeItem?.quantity,
                  src: exchangeItem?.image
                }}
                timeline={returnStatus.timeline}
              /> */}
              {headsupfortails.includes(user.subdomain) &&
                returnStatus.timeline.statuses[ // only to be shown when status is confirmed
                  returnStatus.timeline.reached
                ] === strings[user.lang].requestAccepted && (
                  <div className="bd-primary bd-1 mrg-top-20 pd-15 md-flex xs-flex rounded">
                    <div className="mrg-right-10">{infoIcon()}</div>
                    <div>
                      <div className="fw-600 mrg-btm-5 text-primary-color">
                        Estimated Pickup By:
                      </div>
                      <div className="text-secondary-color">
                        Item(s) will be picked up within 1-3 Business days.
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  icon: PropTypes.any,
  product: PropTypes.any,
  heading: PropTypes.string,
  className: PropTypes.string,
  returnStatus: PropTypes.any,
  user: PropTypes.any
};

Status.propTypes = {
  user: PropTypes.any,
  page: PropTypes.any,
  orderInfo: PropTypes.any,
  returnStatus: PropTypes.any,
  returnStatusHandler: PropTypes.any,
  tracking: PropTypes.any
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page,
  orderInfo: state.orderInfo,
  returnStatus: state.returnStatus
});

const mapDispatchToProps = dispatch => ({
  // orderInfoHandler: query => dispatch(orderInfoHandler(query)),
  // returnResonsHandler: domain => dispatch(returnResonsHandler(domain)),
  // stepChange: step => dispatch(stepChange(step)),
  // returnHandler: query => dispatch(returnHandler(query)),
  returnStatusHandler: query => dispatch(returnStatusHandler(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(Status));
