/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '../../../staticComponents/input';
import {
  actionChange,
  addressChange,
  checkPincodeServiceability,
  stepChange
} from '../../../service';
import toast from 'react-hot-toast';
import actionMessages from '../../../utils/messages';
import {
  addressFields,
  defaultAddress,
  exchangeTypes,
  pumaSubdomains,
  steps
} from '../defaultValues';
import { addressFormValidation } from './validate';
import { useExchangeContext } from '../wrapper';
import { withRouter } from 'react-router-dom';
import { getBooleanfromString } from '../utils/helper';

const phonePattern = new RegExp(/^\d{10}$/);
const pincodePattern = new RegExp(/^\d{6}$/);
const emailPattern = new RegExp(/^.+@.+\..+$/);

const Address = ({
  addresses,
  tracking,
  action,
  addressChange,
  checkPincodeServiceability,
  orderInfo,
  history,
  user
}) => {
  const searchParams = new URLSearchParams(history.location.search);
  const [state, setState] = useState({
    addressForm: defaultAddress,
    isEditing: false,
    editIndex: -1,
    select: action.address.select > -1 ? String(action.address.select) : '0',
    errors: {}
  });
  const { step, goForward, goBack } = useExchangeContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { config, udfKeys } = tracking.theme;
  const selfShipText = tracking?.theme?.meta_data?.udf?.filter(
    item => item.key === 'selfShipText'
  )?.[0]?.value;
  const isMultiExchangeEnabled =
    tracking?.theme?.meta_data?.exchange?.allowMultiExchange;
  const isContactInfoEditable = udfKeys?.isContactInfoEditable
    ? getBooleanfromString(udfKeys?.isContactInfoEditable)
    : true;

  // we are doing a customization where only admin users can edit address or add new address
  const formEditable =
    (config.form_editable && !udfKeys?.adminFormEditable) ||
    (config.form_editable &&
      udfKeys?.adminFormEditable &&
      searchParams.get('source') === 'dashboard');

  const isEmailPresent = JSON.parse(JSON.stringify(addresses[0].email));

  const updatedAddressFields = addressFields.map(field => {
    if (isEmailPresent && field.name === 'email') {
      return { ...field, required: true };
    }
    return field;
  });

  const errorValidation = () => {
    const errors = addressFormValidation(
      state.addressForm,
      updatedAddressFields
    );
    state.errors = errors;
    setState({ ...state });
    return errors;
  };

  const addAddress = () => {
    const { config } = tracking.theme;

    if (Object.keys(errorValidation()).length > 0) return;
    const { phone, email, pincode } = state.addressForm;

    if (
      !(
        (
          (!config.form_editable &&
            (config.country_code === 'SA' || config.country_code === 'IT')) ||
          config.country_code === 'SI'
        ) // Skipping address validation for Slovenia (swybrands)
      )
    ) {
      if (phone && !phonePattern.test(phone)) {
        return toast.error(actionMessages.phone.blank);
      }
      if (pincode && !pincodePattern.test(pincode)) {
        return toast.error(actionMessages.pincode.blank);
      }
      if (email && !emailPattern.test(email)) {
        return toast.error(actionMessages.email.blank);
      }
    }

    if (state.addressForm.isSubmitted) {
      addresses[state.editIndex] = { ...state.addressForm };
    } else {
      addresses.push({ ...state.addressForm, isSubmitted: true });
    }
    addressChange(addresses);
    cancelEditing();
  };

  const onPincodeBlur = e => {
    e.preventDefault();
    const { pincode } = state.addressForm;
    if (e.target.value.length === 6 && pincodePattern.test(pincode)) {
      const toastId = toast.loading('Checking Pincode serviceability');
      checkPincodeServiceability(pincode).then(res => {
        toast.dismiss(toastId);
        const { result } = res;
        if (result.pincode_serviceable) {
          const { city, state: addressState, country } = result.pincode_data;

          state.addressForm = {
            ...state.addressForm,
            city,
            state: addressState,
            country
          };
        } else {
          toast.error(selfShipText ?? actionMessages.pincode.notServiceable);
        }
        state.addressForm.isSelfShipped = result.pincode_serviceable
          ? false
          : true;
        setState({ ...state });
      });
    }
  };

  const nextStep = () => {
    goForward(steps.DATE);
  };

  const selectAddress = e => {
    e.preventDefault();
    setIsSubmitting(true);
    const index = Number(e.target.address.value);

    let address = addresses[index];
    if (
      (!config.form_editable &&
        (config.country_code === 'SA' || config.country_code === 'IT')) ||
      config.country_code === 'SI' // Skipping address validation for Slovenia (swybrands)
    ) {
      action.address.select = index;
      actionChange(action);
      setState({ ...state, isSubmitting: false });
      setIsSubmitting(false);
      return nextStep();
    }
    if (!address.name || !address.phone) {
      setIsSubmitting(false);
      return toast.error(actionMessages.addressForm.missingFields);
    }
    if (address.email && !emailPattern.test(address.email)) {
      setIsSubmitting(false);
      return toast.error(actionMessages.email.blank);
    }
    const toastId = toast.loading('Checking Pincode serviceability');
    checkPincodeServiceability(address.pincode).then(res => {
      toast.dismiss(toastId);
      const { city, state, country } = res.result.pincode_data;
      if (city) {
        address.city = city;
      }
      if (state) {
        address.state = state;
      }
      if (country) {
        address.country = country;
      }
      setIsSubmitting(false);
      if (
        !address.address ||
        !address.phone ||
        !address.city ||
        !address.state ||
        !address.country ||
        !address.pincode
      ) {
        return toast.error(actionMessages.addressForm.missingFields);
      }

      if (res.result.pincode_serviceable) {
        address.isSelfShipped = false;
      } else {
        address.isSelfShipped = true;
        // action.address.select = -1;
        // this.props.actionChange(action);
        return toast.error(
          selfShipText ?? actionMessages.pincode.notServiceable
        );
      }
      action.address.select = index;
      addressChange(addresses);
      actionChange(action);
      nextStep();
    });
  };

  const cancelEditing = () => {
    state.addressForm = { ...defaultAddress };
    state.isEditing = false;
    state.editIndex = -1;
    setState({ ...state });
  };
  const editAddress = (idx = -1) => e => {
    e.stopPropagation();
    state.isEditing = true;
    state.errors = {};
    if (idx !== -1) {
      state.addressForm = { ...addresses[idx] };
      state.editIndex = idx;
    } else {
      state.addressForm = { ...defaultAddress };
      if (!isContactInfoEditable) {
        state.addressForm = {
          ...state.addressForm,
          email: addresses?.[0].email,
          phone: addresses?.[0].phone
        };
      }
    }
    setState({ ...state });
  };

  if (step.current !== steps.ADDRESS && state.isEditing) {
    state.isEditing = false;
    setState({ ...state });
  }
  const isExchange =
    Object.values(orderInfo?.request)?.[0]?.type === 'exchange';

  const onGoBack = () => {
    if (isExchange && !isMultiExchangeEnabled) {
      if (
        Object.values(orderInfo?.request)?.[0]?.exchangeType ===
        exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
      ) {
        return goBack(steps.VARIANT_EXCHANGE);
      }
      if (
        Object.values(orderInfo?.request)?.[0]?.exchangeType ===
        exchangeTypes.DIFFERENT_PRODUCT
      ) {
        return goBack(steps.PRODUCT_EXCHANGE);
      }
      return goBack(steps.REASON);
    }

    return goBack(steps.ITEM_SELECTION);
  };

  return (
    <form onSubmit={selectAddress} className="ht-100 d-flex flex-col">
      <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={onGoBack}
        />
        <span className="fs-20 fw-700 text-capitalize">
          {pumaSubdomains.includes(user.subdomain)
            ? 'Pickup and drop address'
            : 'Select your address'}
        </span>
      </div>
      <div className="ht-100 bd-primary bd-1 xs-bd-0 overflow-auto overflow-x-hidden">
        <div>
          <div className="pd-15">
            {state.isEditing ? (
              <div className="row">
                {updatedAddressFields.map(fields => {
                  const isReadOnly =
                    ['email', 'phone'].includes(fields.name) &&
                    !isContactInfoEditable;
                  return (
                    <div
                      key={fields.name}
                      className={`col-md-${fields.width} ${
                        isReadOnly ? 'disabled' : ''
                      } mrg-btm-15 col-xs-24`}
                    >
                      <div className="fs-16 text-secondary-color">
                        {fields.label}
                        {fields.required ? '*' : ''}
                      </div>
                      <Input
                        {...fields}
                        onBlur={
                          fields.name === 'pincode' ? onPincodeBlur : null
                        }
                        value={state.addressForm[fields.name]}
                        name={fields.name}
                        className="form-control text-primary-color bg-brand-secondary bd-primary-color"
                        onChange={e => {
                          state.addressForm[fields.name] = e.target.value;
                          setState({ ...state });
                        }}
                        readOnly={isReadOnly}
                      />
                      {state.errors?.[fields.name] && (
                        <span className="text-red">
                          {state.errors?.[fields.name]}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                {addresses.map((address, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      state.select = String(i);
                      setState({ ...state });
                    }}
                    className={`cursor-pointer  md-flex xs-flex ${
                      Number(state.select) === i ? 'border-theme' : ''
                    } pd-15 bd-primary bd-1 rounded space-y-3 justify-between`}
                  >
                    <div className="md-flex xs-flex">
                      <label className="radio-input small">
                        <input
                          className="mrg-right-10 mrg-top-5"
                          type="radio"
                          name="address"
                          required
                          checked={String(i) === state.select}
                          value={`${i}`}
                          onChange={e => {
                            state.select = e.target.value;
                            setState({ ...state });
                          }}
                        />
                      </label>
                      <div>
                        <div className="fs-16 fw-600 text-primary-color">
                          {address.name}
                        </div>
                        <div className="fs-14 text-secondary-color">
                          {address.address}
                        </div>
                      </div>
                    </div>
                    {formEditable && (
                      <div>
                        <span
                          className="btn-underline"
                          onClick={editAddress(i)}
                        >
                          Edit
                        </span>
                      </div>
                    )}
                  </div>
                ))}
                {formEditable && (
                  <div
                    className="text-secondary-color btn-underline md-flex xs-flex items-center cursor-pointer"
                    onClick={editAddress()}
                  >
                    <span className="icon-plus fs-10 mrg-right-5" /> Add new
                    address
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {state.isEditing ? (
        <div key="form-edit-buttons" className="row pd-15">
          <div className="col-md-12 col-xs-12">
            <button
              type={'button'}
              className="ant-button rounded-pill full-width"
              onClick={addAddress}
            >
              Done
            </button>
          </div>
          <div className="col-md-12 col-xs-12">
            <button
              type={'button'}
              className="ant-button ghost rounded-pill full-width"
              onClick={cancelEditing}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div key="form-submit-button" className="row mrg-btm-15">
          <div className="col-md-24 pd-left-20 pd-right-20">
            <button
              disabled={state.isEditing || isSubmitting}
              type="submit"
              className="ant-button rounded-pill full-width"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

Address.propTypes = {
  addresses: PropTypes.any,
  actionChange: PropTypes.func,
  addressChange: PropTypes.func,
  checkPincodeServiceability: PropTypes.func,
  action: PropTypes.any,
  tracking: PropTypes.any,
  orderInfo: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.any
  // stepChange: PropTypes.any
};

const mapStateToProps = state => ({
  addresses: state.orderInfo.addresses,
  action: state.orderInfo.action,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  actionChange: query => dispatch(actionChange(query)),
  addressChange: addresses => dispatch(addressChange(addresses)),
  // stepChange: step => dispatch(stepChange(step)),
  checkPincodeServiceability: query =>
    dispatch(checkPincodeServiceability(query))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Address));
