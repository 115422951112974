/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-class-assign */
import React, { Fragment } from 'react';
// import {  Field } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
// import { validateRequest } from './validate';
// import ImageRender from '../imageRender';
import { connect } from 'react-redux';
import { addImage, newRemoveImage as removeImage } from '../../../service';
import { strings } from '../../../utils/localizedStrings';
import { allowedImageTypes } from '../../../utils/entity_mapping';
import toast from 'react-hot-toast';
// import { commonTextRegex } from '../../../utils/helper';

class RequestForm extends React.Component {
  // state = { reason: null, comment: null, subreason: null };
  componentDidMount = () => {
    const { reason, comment, subreason } = this.props.initialValues;
    this.setState({ reason, subreason, comment: comment ?? '', errors: {} });
  };

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    };
  };

  fileRender = e => {
    const { lang, subdomain } = this.props.user;
    if (e.target.files.length > 0) {
      const { name, type } = e.target.files[0];

      if (allowedImageTypes.indexOf(type) > -1) {
        const rawFile = e.target.files[0];
        this.getBase64(e.target.files[0], base64 => {
          var preUrl =
            'returns/' +
            subdomain +
            '/' +
            this.props.orderInfo.reference_number.replaceAll('#', '') +
            '/' +
            name;
          this.props.addImage({
            name,
            base64,
            type,
            preUrl,
            rawFile,
            ids: [this.props.id]
          });
        });
      } else {
        toast.error(strings[lang].invalidImageErr);
      }
    }
  };

  checkValidations = (
    { comment, reason } = {
      comment: this.props.state.comment,
      reason: this.props.state.reason,
      subreason: this.props.state.subreason
    }
  ) => {
    // const { lang } = this.props.user;
    let { errors } = this.state;

    // if (
    //   comment &&
    //   lang === 'en' &&
    //   comment !== String(comment).match(commonTextRegex[lang])?.[0]
    // ) {
    //   errors.comment = `Please enter valid english characters, ${
    //     comment?.[comment?.length - 1]
    //   } is not a valid character`;
    // } else
    if (
      (reason?.label === 'Others' &&
        ['roposo', 'trackmyorder'].includes(this.props.user.subdomain) &&
        !comment) ||
      (['shantanunikhil', 'shantanunikhil-test'].includes(
        this.props.user.subdomain
      ) &&
        !comment)
    ) {
      errors.comment = 'Enter your reason in the comment box';
    } else {
      errors = {};
    }
    this.setState({ errors });
    return errors;
  };

  _handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange(this.props.id, { [e.target.name]: e.target.value });
    this.checkValidations({ comment: e.target.value });
  };

  _selectChange = (key, e) => {
    this.setState({ [key]: e });
    this.props.onChange(this.props.id, { [key]: e });
    this.checkValidations({ [key]: e });
    if (key === 'reason') {
      this.props.reasonChange(e, this.props.id);
      this.setState({ subreason: null });
    }
  };

  onSubmitClick = () => {
    const { reason, subreason, comment } = this.props.state;
    if (Object.keys(this.checkValidations()).length > 0) {
      return;
    }

    this.props.onSubmit(reason, subreason, comment);
  };

  render() {
    const { user, orderInfo, tracking } = this.props;
    const { lang } = user;
    const subreasons = this.props.reasons.list.find(
      reason => reason.label === this.props.state?.reason?.label
    )?.subReasons;
    // let isNextDisabled = true;
    const imgList = this.props.file?.list?.filter(item =>
      item.ids?.includes(this.props.id)
    );
    if (
      (this.props.state.reason && subreasons === undefined) ||
      (this.props.state.reason &&
        subreasons?.length > 0 &&
        this.props.state.subreason)
    ) {
      if (this.props.state.reason?.imageQuantity) {
        if (this.props.state.reason?.imageQuantity === 0) {
          // isNextDisabled = false;
        } else if (imgList.length >= this.props.state.reason?.imageQuantity) {
          // isNextDisabled = false;
        } else if (
          this.props.state.reason?.imageQuantity > 0 &&
          imgList.length !== this.props.state.reason?.imageQuantity
        ) {
          // isNextDisabled = true;
        }
      } else {
        // isNextDisabled = false;
      }
      if (!orderInfo.isCaptureImage) {
        // isNextDisabled = false;
      }
    }
    const isShantanuNikhil = ['shantanunikhil', 'shantanunikhil-test'].includes(
      user.subdomain
    );

    return (
      <div className="row">
        <div className="col-md-24 col-xs-24">
          <section className="reason-box">
            <div className="row">
              <div className="col-md-24 col-xs-24">
                <div className="row">
                  <div className="col-md-24 col-xs-24 mrg-btm-10">
                    <div
                      className={`label mrg-btm-5 ${
                        !this.props.state.reason ? 'red' : ''
                      } ${isShantanuNikhil && 'text-black'} `}
                    >
                      {strings[lang].reason}
                      <span className="mandatory">
                        <strong>*</strong>
                      </span>
                    </div>
                    <Select
                      value={this.props.state.reason}
                      name="reason"
                      onChange={e => this._selectChange('reason', e)}
                      options={this.props.reasons.list}
                      placeholder={strings[lang]?.select ?? 'Select...'}
                      className="custom-select"
                      classNamePrefix="custom-select"
                      simpleValue
                    />
                  </div>

                  {this.props.state?.reason?.subReasons?.length > 0 && (
                    <div className="col-md-24 col-xs-24 mrg-btm-10">
                      <>
                        <div
                          className={`label mrg-btm-5 ${
                            !this.props.state.subreason ? 'red' : ''
                          }`}
                        >
                          Subreason
                          <span className="mandatory">
                            <strong>*</strong>
                          </span>
                        </div>
                        <Select
                          value={this.props.state.subreason}
                          name="subreason"
                          onChange={e => this._selectChange('subreason', e)}
                          options={subreasons}
                          placeholder={strings[lang]?.select ?? 'Select...'}
                          className="custom-select"
                          classNamePrefix="custom-select"
                          simpleValue
                        />
                      </>
                    </div>
                  )}
                  {this.props.applySameReasontoAll &&
                  this.props.state.reason ? (
                    <div className="col-md-24 col-xs-24 mrg-btm-15">
                      <label className="d-flex cursor-pointer">
                        <input
                          type="checkbox"
                          className="mrg-right-5"
                          onChange={this.props.applySameReasontoAll}
                          checked={this.props.applySameReason}
                        />
                        <span className="fs-12">Apply same reason to all</span>
                      </label>
                    </div>
                  ) : null}
                  <div className="col-md-24 col-xs-24 mrg-btm-10">
                    <div className="label">
                      {strings[lang].anyOtherComment}
                      {isShantanuNikhil && (
                        <span className="mandatory">
                          <strong>*</strong>
                        </span>
                      )}
                    </div>
                    <input
                      value={this.props.state.comment || ''}
                      name="comment"
                      onChange={this._handleChange}
                      type="textarea"
                      className={'ant-input'}
                      rows={3}
                      // pattern={commonTextRegex[lang]}
                      placeholder={
                        strings[lang]?.max200Chars ?? 'Max. 200 characters'
                      }
                      maxLength="200"
                    />
                    <div>
                      {this.state?.errors?.comment && (
                        <span className="error text-red">
                          {this.state.errors.comment}
                        </span>
                      )}

                      <span className="pull-right fs-12">
                        {strings[lang]?.charsLeft(
                          200 - (this.props.state?.comment?.length ?? 0)
                        ) ?? ' characters left'}
                      </span>
                    </div>
                  </div>
                  {this.props.isCaptureImage ? (
                    <Fragment>
                      <div className="col-md-24 col-xs-24 pd-top-10 pd-btm-10">
                        <div className="divider-horizontal mrg-top-0 mrg-btm-10"></div>
                        <h3 className="mrg-btm-10">
                          {strings[lang].addImage}
                          {this.props.state.reason?.imageQuantity > 0 ? (
                            <span
                              className={`mandatory ${
                                this.props.state.reason?.imageQuantity > 0
                                  ? ''
                                  : ''
                              }`}
                            >
                              <strong>*</strong>
                            </span>
                          ) : null}
                        </h3>
                        <p className="text-secondary-color fs-12 mrg-btm-10">
                          Only jpg, jpeg, png, heic are allowed
                        </p>
                      </div>
                      <div
                        className={`col-md-24 col-xs-24 ${
                          imgList.length <
                          this.props.state.reason?.imageQuantity
                            ? 'pd-btm-0'
                            : 'pd-btm-20'
                        }`}
                      >
                        {imgList.map((item, i) => (
                          <div
                            key={i}
                            className="uploaded-square-image-container"
                          >
                            <img src={item.base64} alt={item.name} />
                            <span
                              className="icon-close"
                              onClick={e =>
                                this.props.removeImage(
                                  this.props.id,
                                  item.name,
                                  e
                                )
                              }
                            />
                          </div>
                        ))}
                        {imgList.length <
                          this.props.state.reason?.imageQuantity ||
                        imgList.length < 3 ? (
                          <div
                            key={imgList.length + Math.random() * 100}
                            className="upload-square-button-container"
                          >
                            <input
                              type="file"
                              className="fileInput"
                              name="fileInput"
                              accept="image/*,image/heic,image/heif"
                              onChange={this.fileRender}
                            />
                            <span className="icon-plus" />
                          </div>
                        ) : null}
                      </div>
                      {this.props.state.reason?.imageQuantity > 0 ? (
                        <div
                          className={`col-md-24 col-xs-24 pd-btm-20 ${
                            imgList.length <
                            this.props.state.reason?.imageQuantity
                              ? 'show'
                              : 'hide'
                          }`}
                        >
                          <p className="message-required">
                            {strings[lang].pleaseUploadSupportingImage(
                              tracking?.theme?.imageUploadNote ?? '',
                              this.props.state.reason?.imageQuantity
                            )}
                          </p>
                        </div>
                      ) : null}
                    </Fragment>
                  ) : null}

                  {/* <div className="col-md-24 col-xs-24 mrg-top-10 add-image-footer">
                    {!page.sku ? (
                      <button
                        className={`ant-button ghost ${
                          this.props.file.isUploading ? 'disable-element' : ''
                        }`}
                        onClick={this.props.backToList}
                        disabled={this.props.file.isUploading}
                      >
                        {strings[lang].back}
                      </button>
                    ) : null}
                    <button
                      type="submit"
                      className={`ant-button mrg-left-10 ${
                        isNextDisabled || this.props.file.isUploading
                          ? 'disable-element'
                          : ''
                      } ${page.sku ? 'full-width' : ''}`}
                      onClick={this.onSubmitClick}
                      disabled={isNextDisabled || this.props.file.isUploading}
                    >
                      {this.props.file.isUploading
                        ? strings[lang].processing
                        : strings[lang].next}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func,
  reasons: PropTypes.object,
  backToList: PropTypes.func,
  initialValues: PropTypes.object,
  isCaptureImage: PropTypes.bool,
  reasonChange: PropTypes.func,
  file: PropTypes.object,
  tracking: PropTypes.object,
  user: PropTypes.object,
  addImage: PropTypes.func,
  removeImage: PropTypes.func,
  orderInfo: PropTypes.object,
  page: PropTypes.object,
  onChange: PropTypes.func,
  state: PropTypes.object,
  id: PropTypes.number,
  applySameReasontoAll: PropTypes.func,
  applySameReason: PropTypes.bool
};

// export default RequestForm;
const mapStateToProps = state => ({
  file: state.file,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page,
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  addImage: query => dispatch(addImage(query)),
  removeImage: (id, name) => dispatch(removeImage(id, name))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
