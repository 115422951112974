import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { strings } from '../utils/localizedStrings';
import NotFoundCard from './NotFoundCard';
class NotFound extends Component {
  constructor() {
    super();
    this.state = { title: '', website: '' };
  }
  componentDidMount() {
    const { tracking } = this.props;
    const title =
      (tracking.theme.meta_data && tracking.theme.meta_data.title) || '';
    const website =
      (tracking.theme.meta_data && tracking.theme.meta_data.website) || '';
    this.setState({ title, website: website || '' });
  }
  render() {
    const { user, noMessage } = this.props;
    const { lang } = user;
    return (
      <div
        className={`container-fluid main-container pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10
        ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
        ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
        ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
          ${
            user.subdomain == '1mg' || user.subdomain == '1mg-test'
              ? 'onemg'
              : user.subdomain
          }`}
      >
        <Helmet>
          <title>{strings[lang].pageNotFound}</title>
        </Helmet>
        <NotFoundCard
          message={strings[lang].ops404}
          subMessage={strings[lang].notFoundDescFn(
            this.state.title,
            this.state.website
          )}
          noMessage={noMessage}
        />
        {/* <p className="heading pd-btm-20 fs-16">
              {strings[lang].youFoundIncorrectLink}
            </p> */}
      </div>
    );
  }
}

NotFound.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  noMessage: PropTypes.bool
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking
});

export default withRouter(connect(mapStateToProps, null)(NotFound));
